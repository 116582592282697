// stylelint-disable declaration-no-important
:root {
  --x-black: #000;
  --x-gray-100: #F8F9FA;
  --x-gray-200: #E9ECEF;
  --x-gray-300: #DEE2E6;
  --x-gray-400: #CED4DA;
  --x-gray-500: #ADB5BD;
  --x-gray-600: #868E96;
  --x-gray-700: #495057;
  --x-gray-800: #343A40;
  --x-gray-900: #212529;
  --x-danger: #d95c4c;
  --x-dark: #212529;
  --x-info: #17A2B8;
  --x-light: #F8F9FA;
  --x-primary: $JKU-Schwarz;
  --x-secondary: #006893;
  --x-tertiary: #006893;
  --x-success: #90AD25;
  --x-warning: #FFC107;
  --x-white: #fff;
  --x-border-radius: 0.3125rem;
  --x-border-width: 0.0625rem;
  --x-spacer: 0.5rem;
  --x-body-bg-color: var(--x-white);
  --x-body-color: var(--x-gray-900);
  --x-linear-scale-min-width: var(--x-break-sm-unitless);
  --x-linear-scale-max-width: var(--x-break-xl-unitless);
  --x-default-min-font-size: 14;
  --x-default-max-font-size: 15;
  --x-h1-min-font-size: 24;
  --x-h1-max-font-size: 29;
  --x-h2-min-font-size: 20;
  --x-h2-max-font-size: 24;
  --x-h3-min-font-size: 16;
  --x-h3-max-font-size: 21;
  --x-h4-min-font-size: 14;
  --x-h4-max-font-size: 16;
  --x-h5-min-font-size: 14;
  --x-h5-max-font-size: 16;
  --x-small-font-size: 87.5%;
  --x-line-height-base: 1.5;
  --x-sub-sup-font-size: 0.75em;
  --x-input-font-size-sm: 0.875rem;
  --x-input-font-size-lg: 1.125rem;
  --x-input-font-size: 1rem;
  --x-anchor-color: var(--x-tertiary);
  --x-anchor-hover-color: #004460;
  --x-anchor-focus-color: var(--x-secondary);
  --x-body-min-width-unitless: 320;
  --x-break-sm-unitless: 480;
  --x-break-md-unitless: 768;
  --x-break-lg-unitless: 992;
  --x-break-xl-unitless: 1400;
  --x-body-min-width: 20rem;
  --x-break-sm: 30rem;
  --x-break-md: 48rem;
  --x-break-lg: 62rem;
  --x-break-xl: 87.5rem;
  --x-container-xl-max-width: 121.25rem;
  --x-grid-min-gutter-unitless: 15;
  --x-grid-max-gutter-unitless: 30;
  --x-grid-min-gutter: calc(var(--x-grid-min-gutter-unitless) / 16);
  --x-grid-max-gutter: calc(var(--x-grid-max-gutter-unitless) / 16);
  --x-input-border-width: 0.0625rem;
  --x-input-range-thumb-height: 1rem;
  --x-input-range-thumb-width: 1rem;
  --x-input-range-track-height: 0.5rem;
  --x-input-range-track-width: 0.5rem;
  --x-input-range-thumb-disabled-bg-color: var(--x-gray-500);
  --x-input-range-track-bg-color: var(--x-gray-400);
  --x-select-indicator-padding: 1rem;
  --x-form-text-margin-top: 0.25rem;
  --x-form-feedback-margin-top: 0.25rem;
  --x-input-group-addon-bg-color: var(--x-gray-200);
  --x-input-group-addon-border-color: var(--x-input-border-color);
  --x-input-group-addon-color: var(--x-input-color);
  --x-button-padding-y: 0.375rem;
  --x-button-padding-x: 0.625rem;
  --x-button-border-width: 0.0625rem;
  --x-legend-margin-bottom: 0.5rem;
  --x-legend-min-font-size: 14;
  --x-legend-max-font-size: 16;
  --x-input-control-indicator-size: 1rem;
  --x-input-control-gutter: 0.5rem;
  --x-input-border-width: 0.0625rem;
  --x-input-padding-x: 0.75rem;
  --x-input-padding-y: 0.375rem;
  --x-input-line-height: 1.5;
  --x-input-height-border: calc(var(--x-input-border-width) * 2);
  --x-input-height-inner: calc(var(--x-input-line-height) * 1em + var(--x-input-padding-y) * 2);
  --x-input-height: calc(var(--x-input-line-height) * 1em + var(--x-input-padding-y) * 2 + var(--x-input-height-border));
  --x-input-bg-color: var(--x-white);
  --x-input-border-color: var(--x-gray-500);
  --x-input-color: var(--x-gray-700);
  --x-input-disabled-bg-color: var(--x-gray-200);
  --x-input-focus-bg-color: var(--x-white);
  --x-input-focus-border: $JKU-Schwarz;
  --x-input-focus-box-shadow-color: $JKU-Schwarz;
  --x-input-focus-color: var(--x-gray-700);
  --x-input-placeholder-color: var(--x-gray-600);
  --x-input-plaintext-color: var(--x-body-color);
  --x-pagination-active-bg-color: var(--x-tertiary);
  --x-pagination-active-border-color: #00567a;
  --x-pagination-active-color: var(--x-white);
  --x-pagination-bg-color: var(--x-gray-100);
  --x-pagination-border-color: var(--x-gray-400);
  --x-pagination-color: var(--x-dark);
  --x-pagination-disabled-bg-color: var(--x-gray-100);
  --x-pagination-disabled-border-color: var(--x-gray-400);
  --x-pagination-disabled-color: var(--x-gray-600);
  --x-pagination-focus-border-color: var(--x-tertiary);
  --x-pagination-focus-box-shadow-color: rgba(0, 104, 147, 0.5);
  --x-pagination-hover-bg-color: var(--x-gray-300);
  --x-pagination-hover-border-color: var(--x-gray-500);
  --x-pagination-hover-color: var(--x-dark);
  --x-pagination-line-height: 1.5;
  --x-pagination-padding-x: 0.75rem;
  --x-pagination-padding-y: 0.4375rem;
  --x-table-bg-color: transparent;
  --x-table-color: var(--x-body-color);
  --x-table-border-color: var(--x-gray-500);
  --x-table-border-width: 0.0625rem;
  --x-table-cell-padding-sm: var(--x-spacer);
  --x-table-cell-padding: calc(var(--x-spacer) * 2);
  --x-table-head-bg-color: var(--x-gray-200);
  --x-table-head-color: var(--x-gray-700);
  --x-table-hover-bg-color-factor: 0.075;
  --x-table-hover-bg-color: var(--x-gray-100);
  --x-table-hover-color: var(--x-gray-900);
  --x-table-striped-bg-color-factor: 0.05;
  --x-table-striped-bg-color: var(--x-gray-200);
  --x-table-striped-color: var(--x-body-color);
  --x-alert-margin-bottom: 1rem;
  --x-alert-padding-x: 0.75rem;
  --x-alert-padding-y: 0.75rem;
  --x-dropdown-bg-color: var(--x-white);
  --x-dropdown-border-color: var(--x-gray-400);
  --x-dropdown-border-radius: var(--x-border-radius);
  --x-dropdown-border-width: 0.0625rem;
  --x-dropdown-box-shadow-color: rgba(0, 0, 0, 0.175);
  --x-dropdown-color: var(--x-body-color);
  --x-dropdown-divider-bg-color: var(--x-gray-200);
  --x-dropdown-divider-margin-y: calc(var(--x-spacer) / 2);
  --x-dropdown-header-color: var(--x-gray-600);
  --x-dropdown-item-padding-x: 1.5rem;
  --x-dropdown-item-padding-y: 0.25rem;
  --x-dropdown-link-active-bg-color: var(--x-white);
  --x-dropdown-link-active-color: var(--x-body-color);
  --x-dropdown-link-color: var(--x-gray-900);
  --x-dropdown-link-disabled-color: var(--x-gray-600);
  --x-dropdown-link-hover-bg-color: var(--x-white);
  --x-dropdown-link-hover-color: var(--x-tertiary);
  --x-dropdown-padding-y: 0.5rem;
  --x-spinner-color: var(--x-secondary);
  --x-spinner-width: 2rem;
  --x-spinner-height: 2rem;
  --x-spinner-border-width: 0.25rem;
  --x-spinner-animation-speed: 0.75s;
  --x-spinner-width-sm: 1rem;
  --x-spinner-height-sm: 1rem;
  --x-spinner-border-width-sm: 0.1875rem;
  --x-card-bg-color: var(--x-white);
  --x-card-border-color: rgba(0, 0, 0, 0.125);
  --x-card-border-radius: 0.5rem;
  --x-card-border-width: var(--x-border-width);
  --x-card-cap-bg-color: rgba(0, 0, 0, 0.03);
  --x-card-spacer-x: calc(var(--x-spacer) * 2);
  --x-card-spacer-y: var(--x-spacer);
  --x-tooltip-arrow-color: var(--x-tertiary);
  --x-tooltip-arrow-height: 0.5rem;
  --x-tooltip-arrow-width: 0.75rem;
  --x-tooltip-padding-x: 0.375rem;
  --x-tooltip-padding-y: 0.375rem;
  --x-tooltip-margin: 0;
  --x-progress-bar-bg-color: var(--x-secondary);
  --x-progress-bar-color: var(--x-white);
  --x-progress-bg-color: var(--x-gray-200);
  --x-progress-height: 1.5rem;
  --x-progress-bar-animation-timing: 1s linear infinite;
  --x-nav-link-padding-x: 1rem;
  --x-nav-link-padding-y: 0.5rem;
  --x-nav-link-disabled-color: var(--x-gray-700);
  --x-nav-tabs-border-color: var(--x-gray-500);
  --x-nav-tabs-border-width: 0.0625rem;
  --x-nav-tabs-border-radius: var(--x-border-radius);
  --x-nav-tabs-link-hover-border-color: var(--x-gray-400) var(--x-gray-400) var(--x-nav-tabs-border-color);
  --x-nav-tabs-link-active-color: var(--x-black);
  --x-nav-tabs-link-active-bg-color: var(--x-body-bg-color);
  --x-nav-tabs-link-active-border-color: var(--x-nav-tabs-border-color) var(--x-nav-tabs-border-color) var(--x-nav-tabs-link-active-bg-color);
  --x-navbar-nav-link-padding-x: 0.75rem;
  --x-navbar-padding-x: 0;
  --x-navbar-padding-y: 0.5rem;
  --x-navbar-active-color: var(--x-black);
  --x-navbar-brand-color: var(--x-black);
  --x-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
  --x-navbar-color: var(--x-black);
  --x-navbar-disabled-color: rgba(0, 0, 0, 0.3);
  --x-navbar-hover-color: white;
  --x-navbar-toggler-color: var(--x-dark);
  --x-toast-font-size: 0.875rem;
  --x--toast-background-color: var(--x-white);
  --x-toast-border-color: var(--x-gray-400);
  --x-toast-border-width: 0;
  --x-toast-header-background-color: var(--x-gray-200);
  --x-toast-header-border-color: var(--x-gray-400);
  --x-toast-header-color: var(--x-gray-600);
  --x-toast-max-width: 21.875rem;
  --x-toast-padding-x: 0.5rem;
  --x-toast-padding-y: 0.25rem;
  --x-datatable-loader-bg-color: rgba(0, 104, 147, 0.1);
  --x-datatable-group-bg-color: rgba(23, 162, 184, 0.1);
  --x-fullcalendar-non-business-bg-color: rgba(233, 236, 239, 0.5);
  --x-fullcalendar-highlight-bg-color: rgba(0, 104, 147, 0.1);
  --x-fullcalender-popover-box-shadow-color: rgba(0, 0, 0, 0.5);
  --ck-border-radius: rem(0) !important;
  --ck-color-base-foreground: var(--x-gray-100) !important;
  --ck-color-base-border: var(--x-gray-500) !important;
  --ck-color-base-action: var(--x-success) !important;
  --ck-color-base-focus: $JKU-Schwarz !important;
  --ck-color-base-text: var(--x-body-color) !important;
  --ck-color-base-active: var(--x-tertiary) !important;
  --ck-color-base-active-focus: var(--x-tertiary) !important;
  --ck-color-base-error: var(--x-danger) !important;
  --ck-color-focus-border: transparent !important;
  --ck-color-focus-outer-shadow: var(--x-input-focus-box-shadow-color) !important;
  --ck-color-focus-disabled-shadow: var(--x-gray-400) !important;
  --ck-color-button-default-hover-background: var(--x-gray-300) !important;
  --ck-color-button-default-active-background: var(--x-gray-300) !important;
  --ck-color-button-default-active-shadow: var(--x-gray-300) !important;
  --ck-color-button-default-disabled-background: transparent;
  --ck-color-button-save: var(--x-success) !important;
  --ck-color-button-cancel: var(--x-danger) !important;
  --ck-color-switch-button-off-background: var(--x-gray-300) !important;
  --ck-color-link-default: var(--x-secondary) !important;
  --ck-color-link-selected-background: rgba(0, 104, 147, 0.2) !important;
  --ck-color-link-fake-selected: rgba(0, 104, 147, 0.2) !important;
}

.alert {
  background-color: var(--x-alert-bg-color);
  border: var(--x-border-width) solid var(--x-alert-border-color);
  border-radius: var(--x-border-radius);
  color: var(--x-alert-color);
  margin-bottom: var(--x-alert-margin-bottom);
  padding: var(--x-alert-padding-y) var(--x-alert-padding-x);
  position: relative;
}

.alert hr {
  border-top-color: var(--x-alert-hr-border-color);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  color: var(--x-alert-anchor-color);
}

.alert-dismissible .close {
  color: inherit;
  fill: var(--x-alert-anchor-color);
  padding: var(--x-alert-padding-y) var(--x-alert-padding-x);
  position: absolute;
  right: 0;
  top: 0;
}

.alert-black {
  --x-alert-bg-color: #ccc;
  --x-alert-color: #000;
  --x-alert-border-color: #ccc;
  --x-alert-hr-border-color: #bfbfbf;
  --x-alert-anchor-color: #000;
}

.alert-gray-100 {
  --x-alert-bg-color: #fefefe;
  --x-alert-color: #818182;
  --x-alert-border-color: #fefefe;
  --x-alert-hr-border-color: #f1f1f1;
  --x-alert-anchor-color: #686868;
}

.alert-gray-200 {
  --x-alert-bg-color: #fbfbfc;
  --x-alert-color: #797b7c;
  --x-alert-border-color: #fbfbfc;
  --x-alert-hr-border-color: #ececf1;
  --x-alert-anchor-color: #606162;
}

.alert-gray-300 {
  --x-alert-bg-color: #f8f9fa;
  --x-alert-color: #737678;
  --x-alert-border-color: #f8f9fa;
  --x-alert-hr-border-color: #e9ecef;
  --x-alert-anchor-color: #5a5c5e;
}

.alert-gray-400 {
  --x-alert-bg-color: #f5f6f8;
  --x-alert-color: #6b6e71;
  --x-alert-border-color: #f5f6f8;
  --x-alert-hr-border-color: #e6e9ee;
  --x-alert-anchor-color: #525557;
}

.alert-gray-500 {
  --x-alert-bg-color: #eff0f2;
  --x-alert-color: #5a5e62;
  --x-alert-border-color: #eff0f2;
  --x-alert-hr-border-color: #e1e3e7;
  --x-alert-anchor-color: #424547;
}

.alert-gray-600 {
  --x-alert-bg-color: #e7e8ea;
  --x-alert-color: #464a4e;
  --x-alert-border-color: #e7e8ea;
  --x-alert-hr-border-color: #d9dbde;
  --x-alert-anchor-color: #2e3133;
}

.alert-gray-700 {
  --x-alert-bg-color: #dbdcdd;
  --x-alert-color: #262a2d;
  --x-alert-border-color: #dbdcdd;
  --x-alert-hr-border-color: #cecfd1;
  --x-alert-anchor-color: #0f1011;
}

.alert-gray-800 {
  --x-alert-bg-color: #d6d8d9;
  --x-alert-color: #1b1e21;
  --x-alert-border-color: #d6d8d9;
  --x-alert-hr-border-color: #c9cbcd;
  --x-alert-anchor-color: #040505;
}

.alert-gray-900 {
  --x-alert-bg-color: #d3d3d4;
  --x-alert-color: #111315;
  --x-alert-border-color: #d3d3d4;
  --x-alert-hr-border-color: #c6c6c7;
  --x-alert-anchor-color: #000;
}

.alert-danger {
  --x-alert-bg-color: #f8d7da;
  --x-alert-color: #721c24;
  --x-alert-border-color: #f8d7da;
  --x-alert-hr-border-color: #f4c1c6;
  --x-alert-anchor-color: #491217;
}

.alert-dark {
  --x-alert-bg-color: #d3d3d4;
  --x-alert-color: #111315;
  --x-alert-border-color: #d3d3d4;
  --x-alert-hr-border-color: #c6c6c7;
  --x-alert-anchor-color: #000;
}

.alert-info {
  --x-alert-bg-color: #d1ecf1;
  --x-alert-color: #0c5460;
  --x-alert-border-color: #d1ecf1;
  --x-alert-hr-border-color: #bde4eb;
  --x-alert-anchor-color: #062c33;
}

.alert-light {
  --x-alert-bg-color: #fefefe;
  --x-alert-color: #818182;
  --x-alert-border-color: #fefefe;
  --x-alert-hr-border-color: #f1f1f1;
  --x-alert-anchor-color: #686868;
}

.alert-primary {
  --x-alert-bg-color: #fce5cc;
  --x-alert-color: #7c4201;
  --x-alert-border-color: #fce5cc;
  --x-alert-hr-border-color: #fbd9b4;
  --x-alert-anchor-color: #492701;
}

.alert-secondary {
  --x-alert-bg-color: #cce1e9;
  --x-alert-color: #00364c;
  --x-alert-border-color: #cce1e9;
  --x-alert-hr-border-color: #bad7e1;
  --x-alert-anchor-color: #001219;
}

.alert-tertiary {
  --x-alert-bg-color: #cce1e9;
  --x-alert-color: #00364c;
  --x-alert-border-color: #cce1e9;
  --x-alert-hr-border-color: #bad7e1;
  --x-alert-anchor-color: #001219;
}

.alert-success {
  --x-alert-bg-color: #e9efd3;
  --x-alert-color: #4b5a13;
  --x-alert-border-color: #e9efd3;
  --x-alert-hr-border-color: #e0e8c0;
  --x-alert-anchor-color: #28300a;
}

.alert-warning {
  --x-alert-bg-color: #fff3cd;
  --x-alert-color: #856404;
  --x-alert-border-color: #fff3cd;
  --x-alert-hr-border-color: #ffedb4;
  --x-alert-anchor-color: #533f03;
}

.alert-white {
  --x-alert-bg-color: #fff;
  --x-alert-color: #858585;
  --x-alert-border-color: #fff;
  --x-alert-hr-border-color: #f2f2f2;
  --x-alert-anchor-color: #6c6c6c;
}

.badge {
  background-color: var(--x-badge-bg-color);
  border-radius: var(--x-border-radius);
  color: var(--x-badge-color);
  display: inline-block;
  font-size: 75%;
  line-height: 1;
  padding: 0.25rem 0.375rem;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
}

a.badge:hover {
  text-decoration: none;
}

a.badge:focus,
a.badge.focus {
  box-shadow: 0 0 0 0.1875rem var(--x-badge-box-shadow-color);
  outline: 0;
}

.badge:empty {
  display: none;
}

.badge-pill {
  border-radius: 0.625rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.badge-black {
  --x-badge-bg-color: #000;
  --x-badge-color: #fff;
}

a.badge-black {
  --x-badge-box-shadow-color: rgba(0, 0, 0, 0.5);
}

a.badge-black:hover {
  --x-badge-bg-color: #000;
}

.badge-gray-100 {
  --x-badge-bg-color: #F8F9FA;
  --x-badge-color: #212529;
}

a.badge-gray-100 {
  --x-badge-box-shadow-color: rgba(248, 249, 250, 0.5);
}

a.badge-gray-100:hover {
  --x-badge-bg-color: #dae0e5;
}

.badge-gray-200 {
  --x-badge-bg-color: #E9ECEF;
  --x-badge-color: #212529;
}

a.badge-gray-200 {
  --x-badge-box-shadow-color: rgba(233, 236, 239, 0.5);
}

a.badge-gray-200:hover {
  --x-badge-bg-color: #cbd3da;
}

.badge-gray-300 {
  --x-badge-bg-color: #DEE2E6;
  --x-badge-color: #212529;
}

a.badge-gray-300 {
  --x-badge-box-shadow-color: rgba(222, 226, 230, 0.5);
}

a.badge-gray-300:hover {
  --x-badge-bg-color: #c1c9d0;
}

.badge-gray-400 {
  --x-badge-bg-color: #CED4DA;
  --x-badge-color: #212529;
}

a.badge-gray-400 {
  --x-badge-box-shadow-color: rgba(206, 212, 218, 0.5);
}

a.badge-gray-400:hover {
  --x-badge-bg-color: #b1bbc4;
}

.badge-gray-500 {
  --x-badge-bg-color: #ADB5BD;
  --x-badge-color: #212529;
}

a.badge-gray-500 {
  --x-badge-box-shadow-color: rgba(173, 181, 189, 0.5);
}

a.badge-gray-500:hover {
  --x-badge-bg-color: #919ca6;
}

.badge-gray-600 {
  --x-badge-bg-color: #868E96;
  --x-badge-color: #fff;
}

a.badge-gray-600 {
  --x-badge-box-shadow-color: rgba(134, 142, 150, 0.5);
}

a.badge-gray-600:hover {
  --x-badge-bg-color: #6c757d;
}

.badge-gray-700 {
  --x-badge-bg-color: #495057;
  --x-badge-color: #fff;
}

a.badge-gray-700 {
  --x-badge-box-shadow-color: rgba(73, 80, 87, 0.5);
}

a.badge-gray-700:hover {
  --x-badge-bg-color: #32373b;
}

.badge-gray-800 {
  --x-badge-bg-color: #343A40;
  --x-badge-color: #fff;
}

a.badge-gray-800 {
  --x-badge-box-shadow-color: rgba(52, 58, 64, 0.5);
}

a.badge-gray-800:hover {
  --x-badge-bg-color: #1d2124;
}

.badge-gray-900 {
  --x-badge-bg-color: #212529;
  --x-badge-color: #fff;
}

a.badge-gray-900 {
  --x-badge-box-shadow-color: rgba(33, 37, 41, 0.5);
}

a.badge-gray-900:hover {
  --x-badge-bg-color: #0a0c0d;
}

.badge-danger {
  --x-badge-bg-color: #DC3545;
  --x-badge-color: #fff;
}

a.badge-danger {
  --x-badge-box-shadow-color: rgba(220, 53, 69, 0.5);
}

a.badge-danger:hover {
  --x-badge-bg-color: #bd2130;
}

.badge-dark {
  --x-badge-bg-color: #212529;
  --x-badge-color: #fff;
}

a.badge-dark {
  --x-badge-box-shadow-color: rgba(33, 37, 41, 0.5);
}

a.badge-dark:hover {
  --x-badge-bg-color: #0a0c0d;
}

.badge-info {
  --x-badge-bg-color: #17A2B8;
  --x-badge-color: #fff;
}

a.badge-info {
  --x-badge-box-shadow-color: rgba(23, 162, 184, 0.5);
}

a.badge-info:hover {
  --x-badge-bg-color: #117a8b;
}

.badge-light {
  --x-badge-bg-color: #F8F9FA;
  --x-badge-color: #212529;
}

a.badge-light {
  --x-badge-box-shadow-color: rgba(248, 249, 250, 0.5);
}

a.badge-light:hover {
  --x-badge-bg-color: #dae0e5;
}

.badge-primary {
  --x-badge-bg-color: $JKU-Schwarz;
  --x-badge-color: #fff;
}

a.badge-primary {
  --x-badge-box-shadow-color: rgba(238, 127, 1, 0.5);
}

a.badge-primary:hover {
  --x-badge-bg-color: $JKU-Schwarz;
}

.badge-secondary {
  --x-badge-bg-color: #006893;
  --x-badge-color: #fff;
}

a.badge-secondary {
  --x-badge-box-shadow-color: rgba(0, 104, 147, 0.5);
}

a.badge-secondary:hover {
  --x-badge-bg-color: #004460;
}

.badge-tertiary {
  --x-badge-bg-color: #006893;
  --x-badge-color: #fff;
}

a.badge-tertiary {
  --x-badge-box-shadow-color: rgba(0, 104, 147, 0.5);
}

a.badge-tertiary:hover {
  --x-badge-bg-color: #004460;
}

.badge-success {
  --x-badge-bg-color: #90AD25;
  --x-badge-color: #fff;
}

a.badge-success {
  --x-badge-box-shadow-color: rgba(144, 173, 37, 0.5);
}

a.badge-success:hover {
  --x-badge-bg-color: #6d831c;
}

.badge-warning {
  --x-badge-bg-color: #FFC107;
  --x-badge-color: #212529;
}

a.badge-warning {
  --x-badge-box-shadow-color: rgba(255, 193, 7, 0.5);
}

a.badge-warning:hover {
  --x-badge-bg-color: #d39e00;
}

.badge-white {
  --x-badge-bg-color: #fff;
  --x-badge-color: #212529;
}

a.badge-white {
  --x-badge-box-shadow-color: rgba(255, 255, 255, 0.5);
}

a.badge-white:hover {
  --x-badge-bg-color: #e6e6e6;
}

.btn,
.fc .fc-button {
  background-color: var(--x-button-bg-color);
  border: var(--x-border-width) solid var(--x-button-border-color);
  border-radius: var(--x-border-radius);
  color: var(--x-button-color);
  cursor: pointer;
  display: inline-block;
  fill: var(--x-button-color);
  font-family: inherit;
  font-size: var(--x-input-font-size);
  line-height: var(--x-line-height-base);
  padding: var(--x-button-padding-y) var(--x-button-padding-x);
  text-align: center;
  transition: background-color 150ms ease-in-out, border-color 150ms ease-in-out, box-shadow 150ms ease-in-out;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

.btn:focus,
.fc .fc-button:focus,
.focus.btn,
.fc .focus.fc-button {
  box-shadow: 0 0 0 0.1875rem var(--x-button-box-shadow-color);
  outline: 0;
}

.btn:hover,
.fc .fc-button:hover {
  text-decoration: none;
}

.disabled.btn,
.fc .disabled.fc-button,
.btn:disabled,
.fc .fc-button:disabled {
  opacity: 1;
  pointer-events: none;
}

.btn[aria-pressed="true"],
.fc .fc-button[aria-pressed="true"] {
  --x-button-bg-color: black;
  --x-button-border-color: black;
  --x-button-color: #fff;
  --x-button-box-shadow-color: rgba(0, 104, 147, 0.5);
}

.btn[aria-pressed="true"]:focus,
.fc .fc-button[aria-pressed="true"]:focus,
.btn[aria-pressed="true"].focus,
.fc .fc-button[aria-pressed="true"].focus,
.btn[aria-pressed="true"]:hover,
.fc .fc-button[aria-pressed="true"]:hover {
  --x-button-bg-color: black;
  --x-button-border-color: black;
  --x-button-color: #fff;
}

.btn[aria-pressed="true"].disabled,
.fc .fc-button[aria-pressed="true"].disabled,
.btn[aria-pressed="true"]:disabled,
.fc .fc-button[aria-pressed="true"]:disabled {
  --x-button-bg-color: #DEE2E6;
  --x-button-border-color: #DEE2E6;
  --x-button-color: #868E96;
  --x-button-box-shadow-color: rgba(222, 226, 230, 0.5);
}

.btn[aria-pressed="true"].disabled:focus,
.fc .fc-button[aria-pressed="true"].disabled:focus,
.btn[aria-pressed="true"].disabled.focus,
.fc .fc-button[aria-pressed="true"].disabled.focus,
.btn[aria-pressed="true"]:disabled:focus,
.fc .fc-button[aria-pressed="true"]:disabled:focus,
.btn[aria-pressed="true"]:disabled.focus,
.fc .fc-button[aria-pressed="true"]:disabled.focus {
  --x-button-bg-color: #cfd5db;
  --x-button-border-color: #c1c9d0;
}

.btn[aria-pressed="true"] .spinner-border,
.fc .fc-button[aria-pressed="true"] .spinner-border {
  --x-spinner-color: #fff;
}

.btn[aria-pressed="true"] .badge,
.fc .fc-button[aria-pressed="true"] .badge {
  --x-badge-bg-color: #fff;
  --x-badge-color: #212529;
}

a.btn[aria-pressed="true"] .badge,
.fc a.fc-button[aria-pressed="true"] .badge {
  --x-badge-box-shadow-color: rgba(255, 255, 255, 0.5);
}

a.btn[aria-pressed="true"] .badge:hover,
.fc a.fc-button[aria-pressed="true"] .badge:hover {
  --x-badge-bg-color: #e6e6e6;
}

.btn-lg {
  font-size: var(--x-input-font-size-lg);
  line-height: calc(var(--x-line-height-base) * 1.2);
  padding: var(--x-button-padding-y) var(--x-button-padding-x);
}

.btn-sm {
  font-size: var(--x-input-font-size-sm);
  line-height: calc(var(--x-line-height-base) * 0.8);
  padding: var(--x-button-padding-y) var(--x-button-padding-x);
}

.btn-black {
  --x-button-bg-color: #000;
  --x-button-border-color: #000;
  --x-button-color: #fff;
  --x-button-box-shadow-color: rgba(0, 0, 0, 0.5);
}

.btn-black:focus,
.btn-black.focus,
.btn-black:hover {
  --x-button-bg-color: #000;
  --x-button-border-color: #000;
  --x-button-color: #fff;
}

.btn-black.disabled,
.btn-black:disabled {
  --x-button-bg-color: #DEE2E6;
  --x-button-border-color: #DEE2E6;
  --x-button-color: #868E96;
  --x-button-box-shadow-color: rgba(222, 226, 230, 0.5);
}

.btn-black.disabled:focus,
.btn-black.disabled.focus,
.btn-black:disabled:focus,
.btn-black:disabled.focus {
  --x-button-bg-color: #cfd5db;
  --x-button-border-color: #c1c9d0;
}

.btn-black .spinner-border {
  --x-spinner-color: #fff;
}

.btn-gray-100 {
  --x-button-bg-color: #F8F9FA;
  --x-button-border-color: #F8F9FA;
  --x-button-color: #212529;
  --x-button-box-shadow-color: rgba(248, 249, 250, 0.5);
}

.btn-gray-100:focus,
.btn-gray-100.focus,
.btn-gray-100:hover {
  --x-button-bg-color: #e9ecef;
  --x-button-border-color: #dae0e5;
  --x-button-color: #212529;
}

.btn-gray-100.disabled,
.btn-gray-100:disabled {
  --x-button-bg-color: #DEE2E6;
  --x-button-border-color: #DEE2E6;
  --x-button-color: #868E96;
  --x-button-box-shadow-color: rgba(222, 226, 230, 0.5);
}

.btn-gray-100.disabled:focus,
.btn-gray-100.disabled.focus,
.btn-gray-100:disabled:focus,
.btn-gray-100:disabled.focus {
  --x-button-bg-color: #cfd5db;
  --x-button-border-color: #c1c9d0;
}

.btn-gray-100 .spinner-border {
  --x-spinner-color: #212529;
}

.btn-gray-200 {
  --x-button-bg-color: #E9ECEF;
  --x-button-border-color: #E9ECEF;
  --x-button-color: #212529;
  --x-button-box-shadow-color: rgba(233, 236, 239, 0.5);
}

.btn-gray-200:focus,
.btn-gray-200.focus,
.btn-gray-200:hover {
  --x-button-bg-color: #dadfe4;
  --x-button-border-color: #cbd3da;
  --x-button-color: #212529;
}

.btn-gray-200.disabled,
.btn-gray-200:disabled {
  --x-button-bg-color: #DEE2E6;
  --x-button-border-color: #DEE2E6;
  --x-button-color: #868E96;
  --x-button-box-shadow-color: rgba(222, 226, 230, 0.5);
}

.btn-gray-200.disabled:focus,
.btn-gray-200.disabled.focus,
.btn-gray-200:disabled:focus,
.btn-gray-200:disabled.focus {
  --x-button-bg-color: #cfd5db;
  --x-button-border-color: #c1c9d0;
}

.btn-gray-200 .spinner-border {
  --x-spinner-color: #212529;
}

.btn-gray-300 {
  --x-button-bg-color: #DEE2E6;
  --x-button-border-color: #DEE2E6;
  --x-button-color: #212529;
  --x-button-box-shadow-color: rgba(222, 226, 230, 0.5);
}

.btn-gray-300:focus,
.btn-gray-300.focus,
.btn-gray-300:hover {
  --x-button-bg-color: #cfd5db;
  --x-button-border-color: #c1c9d0;
  --x-button-color: #212529;
}

.btn-gray-300.disabled,
.btn-gray-300:disabled {
  --x-button-bg-color: #DEE2E6;
  --x-button-border-color: #DEE2E6;
  --x-button-color: #868E96;
  --x-button-box-shadow-color: rgba(222, 226, 230, 0.5);
}

.btn-gray-300.disabled:focus,
.btn-gray-300.disabled.focus,
.btn-gray-300:disabled:focus,
.btn-gray-300:disabled.focus {
  --x-button-bg-color: #cfd5db;
  --x-button-border-color: #c1c9d0;
}

.btn-gray-300 .spinner-border {
  --x-spinner-color: #212529;
}

.btn-gray-400 {
  --x-button-bg-color: #CED4DA;
  --x-button-border-color: #CED4DA;
  --x-button-color: #212529;
  --x-button-box-shadow-color: rgba(206, 212, 218, 0.5);
}

.btn-gray-400:focus,
.btn-gray-400.focus,
.btn-gray-400:hover {
  --x-button-bg-color: #bfc7cf;
  --x-button-border-color: #b1bbc4;
  --x-button-color: #212529;
}

.btn-gray-400.disabled,
.btn-gray-400:disabled {
  --x-button-bg-color: #DEE2E6;
  --x-button-border-color: #DEE2E6;
  --x-button-color: #868E96;
  --x-button-box-shadow-color: rgba(222, 226, 230, 0.5);
}

.btn-gray-400.disabled:focus,
.btn-gray-400.disabled.focus,
.btn-gray-400:disabled:focus,
.btn-gray-400:disabled.focus {
  --x-button-bg-color: #cfd5db;
  --x-button-border-color: #c1c9d0;
}

.btn-gray-400 .spinner-border {
  --x-spinner-color: #212529;
}

.btn-gray-500 {
  --x-button-bg-color: #ADB5BD;
  --x-button-border-color: #ADB5BD;
  --x-button-color: #212529;
  --x-button-box-shadow-color: rgba(173, 181, 189, 0.5);
}

.btn-gray-500:focus,
.btn-gray-500.focus,
.btn-gray-500:hover {
  --x-button-bg-color: #9fa8b2;
  --x-button-border-color: #919ca6;
  --x-button-color: #212529;
}

.btn-gray-500.disabled,
.btn-gray-500:disabled {
  --x-button-bg-color: #DEE2E6;
  --x-button-border-color: #DEE2E6;
  --x-button-color: #868E96;
  --x-button-box-shadow-color: rgba(222, 226, 230, 0.5);
}

.btn-gray-500.disabled:focus,
.btn-gray-500.disabled.focus,
.btn-gray-500:disabled:focus,
.btn-gray-500:disabled.focus {
  --x-button-bg-color: #cfd5db;
  --x-button-border-color: #c1c9d0;
}

.btn-gray-500 .spinner-border {
  --x-spinner-color: #212529;
}

.btn-gray-600 {
  --x-button-bg-color: #868E96;
  --x-button-border-color: #868E96;
  --x-button-color: #fff;
  --x-button-box-shadow-color: rgba(134, 142, 150, 0.5);
}

.btn-gray-600:focus,
.btn-gray-600.focus,
.btn-gray-600:hover {
  --x-button-bg-color: #78818a;
  --x-button-border-color: #6c757d;
  --x-button-color: #fff;
}

.btn-gray-600.disabled,
.btn-gray-600:disabled {
  --x-button-bg-color: #DEE2E6;
  --x-button-border-color: #DEE2E6;
  --x-button-color: #868E96;
  --x-button-box-shadow-color: rgba(222, 226, 230, 0.5);
}

.btn-gray-600.disabled:focus,
.btn-gray-600.disabled.focus,
.btn-gray-600:disabled:focus,
.btn-gray-600:disabled.focus {
  --x-button-bg-color: #cfd5db;
  --x-button-border-color: #c1c9d0;
}

.btn-gray-600 .spinner-border {
  --x-spinner-color: #fff;
}

.btn-gray-700 {
  --x-button-bg-color: #495057;
  --x-button-border-color: #495057;
  --x-button-color: #fff;
  --x-button-box-shadow-color: rgba(73, 80, 87, 0.5);
}

.btn-gray-700:focus,
.btn-gray-700.focus,
.btn-gray-700:hover {
  --x-button-bg-color: #3d4349;
  --x-button-border-color: #32373b;
  --x-button-color: #fff;
}

.btn-gray-700.disabled,
.btn-gray-700:disabled {
  --x-button-bg-color: #DEE2E6;
  --x-button-border-color: #DEE2E6;
  --x-button-color: #868E96;
  --x-button-box-shadow-color: rgba(222, 226, 230, 0.5);
}

.btn-gray-700.disabled:focus,
.btn-gray-700.disabled.focus,
.btn-gray-700:disabled:focus,
.btn-gray-700:disabled.focus {
  --x-button-bg-color: #cfd5db;
  --x-button-border-color: #c1c9d0;
}

.btn-gray-700 .spinner-border {
  --x-spinner-color: #fff;
}

.btn-gray-800 {
  --x-button-bg-color: #343A40;
  --x-button-border-color: #343A40;
  --x-button-color: #fff;
  --x-button-box-shadow-color: rgba(52, 58, 64, 0.5);
}

.btn-gray-800:focus,
.btn-gray-800.focus,
.btn-gray-800:hover {
  --x-button-bg-color: #292d32;
  --x-button-border-color: #1d2124;
  --x-button-color: #fff;
}

.btn-gray-800.disabled,
.btn-gray-800:disabled {
  --x-button-bg-color: #DEE2E6;
  --x-button-border-color: #DEE2E6;
  --x-button-color: #868E96;
  --x-button-box-shadow-color: rgba(222, 226, 230, 0.5);
}

.btn-gray-800.disabled:focus,
.btn-gray-800.disabled.focus,
.btn-gray-800:disabled:focus,
.btn-gray-800:disabled.focus {
  --x-button-bg-color: #cfd5db;
  --x-button-border-color: #c1c9d0;
}

.btn-gray-800 .spinner-border {
  --x-spinner-color: #fff;
}

.btn-gray-900 {
  --x-button-bg-color: #212529;
  --x-button-border-color: #212529;
  --x-button-color: #fff;
  --x-button-box-shadow-color: rgba(33, 37, 41, 0.5);
}

.btn-gray-900:focus,
.btn-gray-900.focus,
.btn-gray-900:hover {
  --x-button-bg-color: #16181b;
  --x-button-border-color: #0a0c0d;
  --x-button-color: #fff;
}

.btn-gray-900.disabled,
.btn-gray-900:disabled {
  --x-button-bg-color: #DEE2E6;
  --x-button-border-color: #DEE2E6;
  --x-button-color: #868E96;
  --x-button-box-shadow-color: rgba(222, 226, 230, 0.5);
}

.btn-gray-900.disabled:focus,
.btn-gray-900.disabled.focus,
.btn-gray-900:disabled:focus,
.btn-gray-900:disabled.focus {
  --x-button-bg-color: #cfd5db;
  --x-button-border-color: #c1c9d0;
}

.btn-gray-900 .spinner-border {
  --x-spinner-color: #fff;
}

.btn-danger {
  --x-button-bg-color: #DC3545;
  --x-button-border-color: #DC3545;
  --x-button-color: #fff;
  --x-button-box-shadow-color: rgba(220, 53, 69, 0.5);
}

.btn-danger:focus,
.btn-danger.focus,
.btn-danger:hover {
  --x-button-bg-color: #d32535;
  --x-button-border-color: #bd2130;
  --x-button-color: #fff;
}

.btn-danger.disabled,
.btn-danger:disabled {
  --x-button-bg-color: #DEE2E6;
  --x-button-border-color: #DEE2E6;
  --x-button-color: #868E96;
  --x-button-box-shadow-color: rgba(222, 226, 230, 0.5);
}

.btn-danger.disabled:focus,
.btn-danger.disabled.focus,
.btn-danger:disabled:focus,
.btn-danger:disabled.focus {
  --x-button-bg-color: #cfd5db;
  --x-button-border-color: #c1c9d0;
}

.btn-danger .spinner-border {
  --x-spinner-color: #fff;
}

.btn-dark {
  --x-button-bg-color: #212529;
  --x-button-border-color: #212529;
  --x-button-color: #fff;
  --x-button-box-shadow-color: rgba(33, 37, 41, 0.5);
}

.btn-dark:focus,
.btn-dark.focus,
.btn-dark:hover {
  --x-button-bg-color: #16181b;
  --x-button-border-color: #0a0c0d;
  --x-button-color: #fff;
}

.btn-dark.disabled,
.btn-dark:disabled {
  --x-button-bg-color: #DEE2E6;
  --x-button-border-color: #DEE2E6;
  --x-button-color: #868E96;
  --x-button-box-shadow-color: rgba(222, 226, 230, 0.5);
}

.btn-dark.disabled:focus,
.btn-dark.disabled.focus,
.btn-dark:disabled:focus,
.btn-dark:disabled.focus {
  --x-button-bg-color: #cfd5db;
  --x-button-border-color: #c1c9d0;
}

.btn-dark .spinner-border {
  --x-spinner-color: #fff;
}

.btn-info {
  --x-button-bg-color: #17A2B8;
  --x-button-border-color: #17A2B8;
  --x-button-color: #fff;
  --x-button-box-shadow-color: rgba(23, 162, 184, 0.5);
}

.btn-info:focus,
.btn-info.focus,
.btn-info:hover {
  --x-button-bg-color: #148ea1;
  --x-button-border-color: #117a8b;
  --x-button-color: #fff;
}

.btn-info.disabled,
.btn-info:disabled {
  --x-button-bg-color: #DEE2E6;
  --x-button-border-color: #DEE2E6;
  --x-button-color: #868E96;
  --x-button-box-shadow-color: rgba(222, 226, 230, 0.5);
}

.btn-info.disabled:focus,
.btn-info.disabled.focus,
.btn-info:disabled:focus,
.btn-info:disabled.focus {
  --x-button-bg-color: #cfd5db;
  --x-button-border-color: #c1c9d0;
}

.btn-info .spinner-border {
  --x-spinner-color: #fff;
}

.btn-light {
  --x-button-bg-color: #F8F9FA;
  --x-button-border-color: #F8F9FA;
  --x-button-color: #212529;
  --x-button-box-shadow-color: rgba(248, 249, 250, 0.5);
}

.btn-light:focus,
.btn-light.focus,
.btn-light:hover {
  --x-button-bg-color: #e9ecef;
  --x-button-border-color: #dae0e5;
  --x-button-color: #212529;
}

.btn-light.disabled,
.btn-light:disabled {
  --x-button-bg-color: #DEE2E6;
  --x-button-border-color: #DEE2E6;
  --x-button-color: #868E96;
  --x-button-box-shadow-color: rgba(222, 226, 230, 0.5);
}

.btn-light.disabled:focus,
.btn-light.disabled.focus,
.btn-light:disabled:focus,
.btn-light:disabled.focus {
  --x-button-bg-color: #cfd5db;
  --x-button-border-color: #c1c9d0;
}

.btn-light .spinner-border {
  --x-spinner-color: #212529;
}

//.btn-primary {
//  --x-button-bg-color: #EE7F01;
//  --x-button-border-color: #EE7F01;
//  --x-button-color: #fff;
//  --x-button-box-shadow-color: rgba(238, 127, 1, 0.5);
//}
//
//.btn-primary:focus , .btn-primary.focus , .btn-primary:hover {
//  --x-button-bg-color: #d57101;
//  --x-button-border-color: #bb6401;
//  --x-button-color: #fff;
//}
//
.btn-primary.disabled,
.btn-primary:disabled {
  --x-button-bg-color: #DEE2E6;
  --x-button-border-color: #DEE2E6;
  --x-button-color: #868E96;
  --x-button-box-shadow-color: rgba(222, 226, 230, 0.5);
}

.btn-primary.disabled:focus,
.btn-primary.disabled.focus,
.btn-primary:disabled:focus,
.btn-primary:disabled.focus {
  --x-button-bg-color: #cfd5db;
  --x-button-border-color: #c1c9d0;
}

.btn-primary .spinner-border {
  --x-spinner-color: #fff;
}

//.btn-secondary {
//  --x-button-bg-color: #006893;
//  --x-button-border-color: #006893;
//  --x-button-color: #fff;
//  --x-button-box-shadow-color: rgba(0, 104, 147, 0.5);
//}
//
//.btn-secondary:focus , .btn-secondary.focus , .btn-secondary:hover {
//  --x-button-bg-color: #00567a;
//  --x-button-border-color: #004460;
//  --x-button-color: #fff;
//}
//
//.btn-secondary.disabled , .btn-secondary:disabled {
//  --x-button-bg-color: #DEE2E6;
//  --x-button-border-color: #DEE2E6;
//  --x-button-color: #868E96;
//  --x-button-box-shadow-color: rgba(222, 226, 230, 0.5);
//}
//
//.btn-secondary.disabled:focus , .btn-secondary.disabled.focus , .btn-secondary:disabled:focus , .btn-secondary:disabled.focus {
//  --x-button-bg-color: #cfd5db;
//  --x-button-border-color: #c1c9d0;
//}
//
//.btn-secondary .spinner-border {
//  --x-spinner-color: #fff;
//}
//
.btn-tertiary {
  --x-button-bg-color: #006893;
  --x-button-border-color: #006893;
  --x-button-color: #fff;
  --x-button-box-shadow-color: rgba(0, 104, 147, 0.5);
}

.btn-tertiary:focus,
.btn-tertiary.focus,
.btn-tertiary:hover {
  --x-button-bg-color: #00567a;
  --x-button-border-color: #004460;
  --x-button-color: #fff;
}

.btn-tertiary.disabled,
.btn-tertiary:disabled {
  --x-button-bg-color: #DEE2E6;
  --x-button-border-color: #DEE2E6;
  --x-button-color: #868E96;
  --x-button-box-shadow-color: rgba(222, 226, 230, 0.5);
}

.btn-tertiary.disabled:focus,
.btn-tertiary.disabled.focus,
.btn-tertiary:disabled:focus,
.btn-tertiary:disabled.focus {
  --x-button-bg-color: #cfd5db;
  --x-button-border-color: #c1c9d0;
}

.btn-tertiary .spinner-border {
  --x-spinner-color: #fff;
}

.btn-success {
  --x-button-bg-color: #90AD25;
  --x-button-border-color: #90AD25;
  --x-button-color: #fff;
  --x-button-box-shadow-color: rgba(144, 173, 37, 0.5);
}

.btn-success:focus,
.btn-success.focus,
.btn-success:hover {
  --x-button-bg-color: #7f9821;
  --x-button-border-color: #6d831c;
  --x-button-color: #fff;
}

.btn-success.disabled,
.btn-success:disabled {
  --x-button-bg-color: #DEE2E6;
  --x-button-border-color: #DEE2E6;
  --x-button-color: #868E96;
  --x-button-box-shadow-color: rgba(222, 226, 230, 0.5);
}

.btn-success.disabled:focus,
.btn-success.disabled.focus,
.btn-success:disabled:focus,
.btn-success:disabled.focus {
  --x-button-bg-color: #cfd5db;
  --x-button-border-color: #c1c9d0;
}

.btn-success .spinner-border {
  --x-spinner-color: #fff;
}

.btn-warning {
  --x-button-bg-color: #FFC107;
  --x-button-border-color: #FFC107;
  --x-button-color: #212529;
  --x-button-box-shadow-color: rgba(255, 193, 7, 0.5);
}

.btn-warning:focus,
.btn-warning.focus,
.btn-warning:hover {
  --x-button-bg-color: #edb100;
  --x-button-border-color: #d39e00;
  --x-button-color: #212529;
}

.btn-warning.disabled,
.btn-warning:disabled {
  --x-button-bg-color: #DEE2E6;
  --x-button-border-color: #DEE2E6;
  --x-button-color: #868E96;
  --x-button-box-shadow-color: rgba(222, 226, 230, 0.5);
}

.btn-warning.disabled:focus,
.btn-warning.disabled.focus,
.btn-warning:disabled:focus,
.btn-warning:disabled.focus {
  --x-button-bg-color: #cfd5db;
  --x-button-border-color: #c1c9d0;
}

.btn-warning .spinner-border {
  --x-spinner-color: #212529;
}

.btn-white {
  --x-button-bg-color: #fff;
  --x-button-border-color: #fff;
  --x-button-color: #212529;
  --x-button-box-shadow-color: rgba(255, 255, 255, 0.5);
}

.btn-white:focus,
.btn-white.focus,
.btn-white:hover {
  --x-button-bg-color: #f2f2f2;
  --x-button-border-color: #e6e6e6;
  --x-button-color: #212529;
}

.btn-white.disabled,
.btn-white:disabled {
  --x-button-bg-color: #DEE2E6;
  --x-button-border-color: #DEE2E6;
  --x-button-color: #868E96;
  --x-button-box-shadow-color: rgba(222, 226, 230, 0.5);
}

.btn-white.disabled:focus,
.btn-white.disabled.focus,
.btn-white:disabled:focus,
.btn-white:disabled.focus {
  --x-button-bg-color: #cfd5db;
  --x-button-border-color: #c1c9d0;
}

.btn-white .spinner-border {
  --x-spinner-color: #212529;
}

.btn-loader {
  color: transparent !important;
  cursor: default;
  fill: transparent !important;
  pointer-events: none;
  position: relative;
}

.btn-loader .spinner-border-sm {
  left: 50%;
  margin-left: calc(var(--x-spinner-height-sm) / -2);
  margin-top: calc(var(--x-spinner-height-sm) / -2);
  position: absolute;
  top: 50%;
}

.btn-group {
  display: inline-flex;
  position: relative;
  vertical-align: middle;
}

.btn-group > .btn {
  flex: 1 1 auto;
  position: relative;
}

.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group > .btn:focus,
.btn-group > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:not(:first-child) {
  margin-left: calc(var(--x-button-border-width) * -1);
}

.btn-group > .btn:not(:last-child) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.btn-group > .btn:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.card {
  background-clip: border-box;
  //background-color: var(--x-card-bg-color);
  border: var(--x-card-border-width) solid var(--x-card-border-color);
  border-radius: var(--x-card-border-radius);
  display: flex;
  flex-direction: column;
  min-width: 0;
  position: relative;
  word-wrap: break-word;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-radius: var(--x-card-border-radius) var(--x-card-border-radius) 0 0;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-radius: 0 0 var(--x-card-border-radius) var(--x-card-border-radius);
}

.card-header {
  background-color: var(--x-card-cap-bg-color);
  border-bottom: var(--x-card-border-width) solid var(--x-card-border-color);
  border-radius: var(--x-card-border-radius) var(--x-card-border-radius) 0 0;
  margin-bottom: 0;
  padding: var(--x-card-spacer-y) var(--x-card-spacer-x);
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--x-card-spacer-y) var(--x-card-spacer-x);
}

.card-title {
  margin-bottom: var(--x-card-spacer-y);
}

.card-footer {
  background-color: var(--x-card-cap-bg-color);
  border-radius: 0 0 var(--x-card-border-radius) var(--x-card-border-radius);
  border-top: var(--x-card-border-width) solid var(--x-card-border-color);
  padding: var(--x-card-spacer-y) var(--x-card-spacer-x);
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}

.accordion > .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}

.accordion > .card:first-of-type {
  border-bottom: 0;
  border-radius: var(--x-card-border-radius) var(--x-card-border-radius) 0 0;
}

.accordion > .card:last-of-type {
  border-radius: 0 0 var(--x-card-border-radius) var(--x-card-border-radius);
}

.accordion > .card .card-header {
  margin-bottom: var(--x-card-border-width) !important;
}

.column-1 {
  column-count: 1;
  column-gap: 2.5rem;
}

.column-2 {
  column-count: 2;
  column-gap: 2.5rem;
}

.column-3 {
  column-count: 3;
  column-gap: 2.5rem;
}

.column-4 {
  column-count: 4;
  column-gap: 2.5rem;
}

.column-5 {
  column-count: 5;
  column-gap: 2.5rem;
}

.column-6 {
  column-count: 6;
  column-gap: 2.5rem;
}

@media (min-width: 30rem) {
  .column-sm-1 {
    column-count: 1;
    column-gap: 2.5rem;
  }

  .column-sm-2 {
    column-count: 2;
    column-gap: 2.5rem;
  }

  .column-sm-3 {
    column-count: 3;
    column-gap: 2.5rem;
  }

  .column-sm-4 {
    column-count: 4;
    column-gap: 2.5rem;
  }

  .column-sm-5 {
    column-count: 5;
    column-gap: 2.5rem;
  }

  .column-sm-6 {
    column-count: 6;
    column-gap: 2.5rem;
  }
}

@media (min-width: 48rem) {
  .column-md-1 {
    column-count: 1;
    column-gap: 2.5rem;
  }

  .column-md-2 {
    column-count: 2;
    column-gap: 2.5rem;
  }

  .column-md-3 {
    column-count: 3;
    column-gap: 2.5rem;
  }

  .column-md-4 {
    column-count: 4;
    column-gap: 2.5rem;
  }

  .column-md-5 {
    column-count: 5;
    column-gap: 2.5rem;
  }

  .column-md-6 {
    column-count: 6;
    column-gap: 2.5rem;
  }
}

@media (min-width: 62rem) {
  .column-lg-1 {
    column-count: 1;
    column-gap: 2.5rem;
  }

  .column-lg-2 {
    column-count: 2;
    column-gap: 2.5rem;
  }

  .column-lg-3 {
    column-count: 3;
    column-gap: 2.5rem;
  }

  .column-lg-4 {
    column-count: 4;
    column-gap: 2.5rem;
  }

  .column-lg-5 {
    column-count: 5;
    column-gap: 2.5rem;
  }

  .column-lg-6 {
    column-count: 6;
    column-gap: 2.5rem;
  }
}

@media (min-width: 87.5rem) {
  .column-xl-1 {
    column-count: 1;
    column-gap: 2.5rem;
  }

  .column-xl-2 {
    column-count: 2;
    column-gap: 2.5rem;
  }

  .column-xl-3 {
    column-count: 3;
    column-gap: 2.5rem;
  }

  .column-xl-4 {
    column-count: 4;
    column-gap: 2.5rem;
  }

  .column-xl-5 {
    column-count: 5;
    column-gap: 2.5rem;
  }

  .column-xl-6 {
    column-count: 6;
    column-gap: 2.5rem;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  content: "";
  display: inline-block;
  margin-left: 0.375rem;
  vertical-align: 0.25rem;
  border-bottom: 0;
  border-left: 0.25rem solid transparent;
  border-right: 0.25rem solid transparent;
  border-top: 0.25rem solid;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  background-clip: padding-box;
  background-color: var(--x-dropdown-bg-color);
  border: var(--x-dropdown-border-width) solid var(--x-dropdown-border-color);
  border-radius: var(--x-dropdown-border-radius);
  box-shadow: 0 0.125rem 0.5rem var(--x-dropdown-box-shadow-color);
  color: var(--xdropdown-color);
  display: none;
  float: left;
  left: 0;
  list-style: none;
  margin: 0.125rem 0 0;
  min-width: 10rem;
  padding: 0.5rem 0;
  position: absolute;
  text-align: left;
  top: 100%;
  z-index: 1000;
}

.dropdown-menu-left {
  left: 0;
  right: auto;
}

.dropdown-menu-right {
  left: auto;
  right: 0;
}

@media (min-width: 30rem) {
  .dropdown-menu-sm-left {
    left: 0;
    right: auto;
  }

  .dropdown-menu-sm-right {
    left: auto;
    right: 0;
  }
}

@media (min-width: 48rem) {
  .dropdown-menu-md-left {
    left: 0;
    right: auto;
  }

  .dropdown-menu-md-right {
    left: auto;
    right: 0;
  }
}

@media (min-width: 62rem) {
  .dropdown-menu-lg-left {
    left: 0;
    right: auto;
  }

  .dropdown-menu-lg-right {
    left: auto;
    right: 0;
  }
}

@media (min-width: 87.5rem) {
  .dropdown-menu-xl-left {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xl-right {
    left: auto;
    right: 0;
  }
}

.dropup .dropdown-menu {
  bottom: 100%;
  margin-bottom: 0.125rem;
  margin-top: 0;
  top: auto;
}

.dropup .dropdown-toggle::after {
  content: "";
  display: inline-block;
  margin-left: 0.375rem;
  vertical-align: 0.25rem;
  border-bottom: 0.25rem solid;
  border-left: 0.25rem solid transparent;
  border-right: 0.25rem solid transparent;
  border-top: 0;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  left: 100%;
  margin-left: 0.125rem;
  margin-top: 0;
  right: auto;
  top: 0;
}

.dropright .dropdown-toggle::after {
  content: "";
  display: inline-block;
  margin-left: 0.375rem;
  vertical-align: 0.25rem;
  border-bottom: 0.25rem solid transparent;
  border-left: 0.25rem solid;
  border-right: 0;
  border-top: 0.25rem solid transparent;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  left: auto;
  margin-right: 0.125rem;
  margin-top: 0;
  right: 100%;
  top: 0;
}

.dropleft .dropdown-toggle::after {
  content: "";
  display: inline-block;
  margin-left: 0.375rem;
  vertical-align: 0.25rem;
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  content: "";
  display: inline-block;
  margin-right: 0.25rem;
  vertical-align: 0.25rem;
  border-bottom: 0.25rem solid transparent;
  border-right: 0.25rem solid;
  border-top: 0.25rem solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"] {
  bottom: auto;
  right: auto;
}

.dropdown-divider {
  border-top: var(--x-dropdown-border-width) solid var(--x-dropdown-divider-bg-color);
  height: 0;
  margin: var(--x-dropdown-divider-margin-y) 0;
  overflow: hidden;
}

.dropdown-item {
  background-color: transparent;
  border: 0;
  clear: both;
  color: var(--x-dropdown-link-color);
  display: block;
  padding: var(--x-dropdown-item-padding-y) var(--x-dropdown-item-padding-x);
  text-align: inherit;
  white-space: nowrap;
  width: 100%;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: var(--x-dropdown-link-hover-bg-color);
  color: var(--x-dropdown-link-hover-color);
  text-decoration: none;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--x-dropdown-link-active-bg-color);
  color: var(--x-dropdown-link-active-color);
  text-decoration: none;
}

.dropdown-item.active::after,
.dropdown-item:active::after {
  border: 0 !important;
}

.dropdown-item.active:focus,
.dropdown-item.active:hover,
.dropdown-item:active:focus,
.dropdown-item:active:hover {
  background-color: var(--x-dropdown-link-hover-bg-color);
  color: var(--x-dropdown-link-hover-color);
  text-decoration: none;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  background-color: transparent;
  color: var(--x-dropdown-link-disabled-color);
  pointer-events: none;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  color: var(--x-dropdown-header-color);
  display: block;
  margin-bottom: 0;
  padding: var(--x-dropdown-padding-y) var(--x-dropdown-item-padding-x);
  white-space: nowrap;
}

.dropdown-item-text {
  color: var(--x-dropdown-link-color);
  display: block;
  padding: var(--x-dropdown-item-padding-y) var(--x-dropdown-item-padding-x);
}

.custom-control {
  display: block;
  min-height: calc(rem($input-font-size) * var(--x-line-height-base));
  padding-left: calc(var(--x-input-control-gutter) + var(--x-input-control-indicator-size));
  position: relative;
}

.custom-control-label {
  margin-bottom: 0;
  margin-top: calc(var(--x-input-padding-y) + var(--x-input-border-width));
  position: relative;
  vertical-align: top;
}

.custom-control-label::before {
  background-color: var(--x-white);
  border: var(--x-border-width) solid var(--x-input-border-color);
  content: "";
  display: block;
  height: var(--x-input-control-indicator-size);
  left: calc((var(--x-input-control-indicator-size) + var(--x-input-control-gutter)) * -1);
  pointer-events: none;
  position: absolute;
  top: calc((var(--x-input-font-size) * var(--x-line-height-base) - var(--x-input-control-indicator-size)) / 3);
  transition: border-color ease-in-out 150ms, box-shadow ease-in-out 150ms;
  width: var(--x-input-control-indicator-size);
}

.custom-control-label::after {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 50%;
  content: "";
  display: block;
  height: var(--x-input-control-indicator-size);
  left: calc((var(--x-input-control-indicator-size) + var(--x-input-control-gutter)) * -1);
  position: absolute;
  top: calc((var(--x-input-font-size) * var(--x-line-height-base) - var(--x-input-control-indicator-size)) / 3);
  width: var(--x-input-control-indicator-size);
}

.custom-control-input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: var(--x-input-focus-border);
  border-color: var(--x-input-focus-border);
}

.custom-control-input:focus ~ .custom-control-label::before {
  border-color: var(--x-input-focus-border);
  box-shadow: 0 0 0 0.1875rem var(--x-input-focus-box-shadow-color);
}

.custom-control-input[disabled] ~ .custom-control-label,
.custom-control-input[readonly] ~ .custom-control-label {
  color: var(--x-gray-600);
  pointer-events: none;
}

.custom-control-input[disabled] ~ .custom-control-label::before,
.custom-control-input[readonly] ~ .custom-control-label::before {
  background-color: var(--x-gray-200);
  border-color: var(--x-gray-600);
}

.custom-control-input:checked[disabled] ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23868E96' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e") !important;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-checkbox .custom-control-label::before {
  border-radius: var(--x-border-radius);
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-control[list],
.custom-select,
.custom-date {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 2 4 5'%3e%3cpath fill='%23343A40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center;
  background-repeat: no-repeat;
  background-size: 0.5rem 0.625rem;
}

.form-control {
  background-clip: padding-box;
  background-color: var(--x-input-bg-color);
  border: var(--x-border-width) solid var(--x-input-border-color);
  border-radius: var(--x-border-radius);
  color: var(--x-input-color);
  display: block;
  font-size: var(--x-input-font-size);
  height: var(--x-input-height);
  line-height: var(--x-input-line-height);
  padding: var(--x-input-padding-y) var(--x-input-padding-x);
  transition: border-color ease-in-out 150ms, box-shadow ease-in-out 150ms;
  width: 100%;
}

.form-control:focus {
  background-color: var(--x-input-focus-bg-color);
  border-color: var(--x-input-focus-border);
  box-shadow: 0 0 0 0.1875rem var(--x-input-focus-box-shadow-color);
  color: var(--x-input-focus-color);
  outline: 0;
}

.form-control:disabled {
  cursor: not-allowed;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: var(--x-input-disabled-bg-color);
  opacity: 1;
}

.form-control::placeholder {
  color: var(--x-input-placeholder-color);
  opacity: 1;
}

.form-control-plaintext {
  background-color: transparent;
  border: solid transparent;
  color: var(--x-input-plaintext-color);
  display: block;
  font-size: var(--x-input-font-size);
  height: var(--x-input-height);
  line-height: var(--x-input-line-height);
  line-height: var(--x-input-line-height);
  margin-bottom: 0;
  padding-bottom: var(--x-input-padding-y);
  padding-top: var(--x-input-padding-y);
  width: 100%;
}

.form-control[list]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  opacity: 0;
}

.custom-select {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--x-input-bg-color);
  border: var(--x-border-width) solid var(--x-input-border-color);
  border-radius: var(--x-border-radius);
  color: var(--x-input-color);
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  //font-size: var(--x-input-font-size);
  font-size: 100%;
  height: var(--x-input-height);
  line-height: var(--x-input-line-height);
  margin: 0;
  padding: var(--x-input-padding-y) calc(var(--x-input-padding-x) + var(--x-select-indicator-padding)) var(--x-input-padding-y) var(--x-input-padding-x);
  text-transform: none;
  transition: border-color ease-in-out 150ms, box-shadow ease-in-out 150ms;
  vertical-align: middle;
  width: 100%;
  word-wrap: normal;
}

.custom-select:focus {
  background-color: var(--x-input-focus-bg-color);
  border-color: var(--x-input-focus-border);
  box-shadow: 0 0 0 0.1875rem var(--x-input-focus-box-shadow-color);
  color: var(--x-input-focus-color);
  outline: 0;
}

.custom-select:focus::-ms-value {
  background-color: var(--x-input-bg-color);
  color: var(--x-input-color);
}

.custom-select:disabled {
  background-color: var(--x-input-disabled-bg-color);
  cursor: not-allowed;
  opacity: 1;
}

.custom-select[readonly] {
  background-color: var(--x-input-disabled-bg-color);
  pointer-events: none;
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  background-image: none;
  height: auto;
  padding-right: var(--x-input-padding-x);
}

.custom-select[multiple] option,
.custom-select[size]:not([size="1"]) option {
  margin: calc(var(--x-spacer) / 2) 0;
}

.custom-select::-ms-expand {
  display: none;
}

optgroup {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

.mce-tinymce {
  border: var(--x-border-width) solid var(--x-input-border-color) !important;
  border-radius: var(--x-border-radius);
  overflow: hidden;
  transition: border-color ease-in-out 150ms, box-shadow ease-in-out 150ms;
}

.mce-tinymce.focus {
  border-color: var(--x-input-focus-border) !important;
  box-shadow: 0 0 0 0.1875rem var(--x-input-focus-box-shadow-color);
}

.mce-tinymce.is-invalid {
  border-color: var(--x-danger) !important;
}

.mce-tinymce.is-invalid.focus {
  border-color: var(--x-danger) !important;
  box-shadow: 0 0 0 0.1875rem rgba(220, 53, 69, 0.25);
}

.mce-tinymce.is-invalid ~ .invalid-feedback {
  display: block;
}

.form-inline {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
}

.form-inline label {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 0;
  margin-right: var(--x-spacer);
}

.form-inline .form-control {
  display: inline-block;
  vertical-align: middle;
  width: auto;
}

.form-inline .custom-select {
  width: auto;
}

.form-inline .custom-date {
  min-width: inherit;
}

fieldset {
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0;
}

legend {
  --x-linear-scale-min-value: var(--x-legend-min-font-size);
  --x-linear-scale-max-value: var(--x-legend-max-font-size);
  --x-slope: (var(--x-linear-scale-max-value) - var(--x-linear-scale-min-value)) / (var(--x-linear-scale-max-width) - var(--x-linear-scale-min-width));
  --x-y-axis-intersection: ((var(--x-linear-scale-min-width) * -1 / 16) * var(--x-slope) + (var(--x-linear-scale-min-value) / 16));
  font-size: clamp(calc(var(--x-linear-scale-min-value) / 16 * 1rem), calc(var(--x-y-axis-intersection) * 1rem + var(--x-slope) * 100vw), calc(var(--x-linear-scale-max-value) / 16 * 1rem));
  color: inherit;
  cursor: default !important;
  display: block;
  line-height: inherit;
  margin-bottom: var(--x-legend-margin-bottom);
  max-width: 100%;
  padding: 0;
  white-space: normal;
  width: 100%;
}

legend.label {
  margin-bottom: 0;
}

.custom-file {
  display: inline-block;
  height: var(--x-input-height);
  margin-bottom: 0;
  position: relative;
  width: 100%;
}

.custom-file-input {
  cursor: pointer;
  height: var(--x-input-height);
  margin: 0;
  opacity: 0;
  position: relative;
  width: 100%;
  z-index: 2;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: var(--x-input-focus-border);
  box-shadow: 0 0 0 0.1875rem var(--x-input-focus-box-shadow-color);
}

.custom-file-input:disabled ~ .custom-file-label {
  background-color: var(--x-input-disabled-bg-color);
}

.custom-file-input:lang(de) ~ .custom-file-label::after {
  content: "Durchsuchen";
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  background-color: var(--x-input-bg-color);
  border: var(--x-input-border-width) solid var(--x-input-border-color);
  border-radius: var(--x-border-radius);
  color: var(--x-input-color);
  font-size: var(--x-input-font-size);
  height: var(--x-input-height);
  left: 0;
  line-height: var(--x-input-line-height);
  overflow: hidden;
  padding: var(--x-input-padding-y) var(--x-input-padding-x);
  position: absolute;
  right: 0;
  top: 0;
  white-space: nowrap;
  z-index: 1;
}

.custom-file-label::after {
  background: var(--x-gray-200);
  border-left: inherit;
  border-radius: 0 var(--x-border-radius) var(--x-border-radius) 0;
  bottom: 0;
  color: var(--x-input-color);
  display: block;
  height: var(--x-input-height-inner);
  line-height: var(--x-input-line-height);
  padding: var(--x-input-padding-y) var(--x-input-padding-x);
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
}

.input-group {
  align-items: stretch;
  display: flex;
  position: relative;
  width: 100%;
}

.input-group > .form-control {
  flex: 1 1 0%;
  margin-bottom: 0;
  min-width: 0;
  position: relative;
}

.input-group > .form-control:focus {
  z-index: 3;
}

.input-group > .form-control:not(:last-child) {
  border-radius: var(--x-border-radius) 0 0 var(--x-border-radius);
}

.input-group > .form-control:not(:first-child) {
  border-radius: 0 var(--x-border-radius) var(--x-border-radius) 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend {
  margin-right: calc(var(--x-input-border-width) * -1);
}

.input-group-append {
  margin-left: calc(var(--x-input-border-width) * -1);
}

.input-group-text {
  align-items: center;
  background-color: var(--x-input-group-addon-bg-color);
  border: var(--x-border-width) solid var(--x-input-group-addon-border-color);
  border-radius: var(--x-border-radius);
  color: var(--x-input-group-addon-color);
  display: flex;
  font-size: var(--x-input-font-size);
  line-height: var(--x-input-line-height);
  margin-bottom: 0;
  padding: var(--x-input-padding-y) var(--x-input-padding-x);
  text-align: center;
  white-space: nowrap;
}

.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-radius: var(--x-border-radius) 0 0 var(--x-border-radius);
}

.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-radius: 0 var(--x-border-radius) var(--x-border-radius) 0;
}

label,
.label {
  --x-linear-scale-min-value: var(--x-default-min-font-size);
  --x-linear-scale-max-value: var(--x-default-max-font-size);
  --x-slope: (var(--x-linear-scale-max-value) - var(--x-linear-scale-min-value)) / (var(--x-linear-scale-max-width) - var(--x-linear-scale-min-width));
  --x-y-axis-intersection: ((var(--x-linear-scale-min-width) * -1 / 16) * var(--x-slope) + (var(--x-linear-scale-min-value) / 16));
  font-size: clamp(calc(var(--x-linear-scale-min-value) / 16 * 1rem), calc(var(--x-y-axis-intersection) * 1rem + var(--x-slope) * 100vw), calc(var(--x-linear-scale-max-value) / 16 * 1rem));
  cursor: pointer;
  display: inline-block;
  line-height: var(--x-input-line-height);
  margin-bottom: calc(var(--x-input-padding-y) + var(--x-input-border-width));
}

input {
  border-radius: 0;
  box-shadow: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
  overflow: visible;
}

input[type="search"] {
  -webkit-appearance: none;
  appearance: none;
  outline-offset: -0.125rem;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

input[type="date"],
input[type="datetime-local"],
input[type="time"],
input[type="month"] {
  cursor: text;
  min-width: 100%;
}

input::-webkit-contacts-auto-fill-button,
input::-webkit-credentials-auto-fill-button {
  pointer-events: none;
  visibility: hidden;
}

input::-webkit-clear-button,
input::-ms-clear {
  display: none;
}

textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
  overflow: auto;
  resize: vertical;
}

textarea[disabled] {
  cursor: not-allowed;
}

textarea.form-control {
  height: auto;
}

button {
  border-radius: 0;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
  overflow: hidden;
  text-transform: none;
  -webkit-user-select: none;
  user-select: none;
}

button:focus {
  outline: 0.0625rem dotted;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button, [type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  appearance: button;
}

.form-group {
  margin-bottom: 1rem;
}

.form-control[list],
.custom-select,
.custom-date {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 2 4 5'%3e%3cpath fill='%23343A40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center;
  background-repeat: no-repeat;
  background-size: 0.5rem 0.625rem;
}

.form-control-sm {
  font-size: var(--x-input-font-size-sm);
  height: 2rem;
  padding: 0.125rem 0.375rem;
}

.custom-date::-webkit-inner-spin-button,
.custom-date::-webkit-outer-spin-button {
  visibility: hidden;
}

.custom-date::-webkit-calendar-picker-indicator {
  cursor: pointer;
  margin: 0;
  opacity: 0;
  width: 0.625rem;
}

.custom-number {
  -moz-appearance: textfield;
}

.custom-number::-webkit-inner-spin-button,
.custom-number::-webkit-outer-spin-button,
.custom-time::-webkit-inner-spin-button,
.custom-time::-webkit-outer-spin-button {
  display: none;
}

.custom-select-sm {
  background-position: right 0.5rem center;
  font-size: var(--x-input-font-size-sm);
  height: 2rem;
  line-height: 1.625rem;
  padding: 0.125rem 1.375rem 0.125rem 0.375rem;
}

.form-text {
  display: block;
  margin-top: var(--x-form-text-margin-top);
}

.form-text ~ .invalid-feedback {
  margin: 0;
}

.errorlist {
  margin-bottom: 0;
  padding-left: calc(var(--x-spacer) * 2);
}

.form-group .errorlist {
  list-style: none;
  padding-left: 0;
}

.invalid-feedback {
  color: var(--x-danger);
  display: none;
  margin-top: var(--x-form-feedback-margin-top);
  width: 100%;
}

.invalid-feedback:hover {
  color: var(--x-danger) !important;
  text-decoration: none;
}

.form-control.is-invalid, .custom-select.is-invalid, .input-wrapper.is-invalid,
.form-set-label.is-invalid {
  border-color: var(--x-danger);
}

.form-control.is-invalid:focus, .custom-select.is-invalid:focus, .input-wrapper.is-invalid:focus,
.form-set-label.is-invalid:focus {
  border-color: var(--x-danger);
  box-shadow: 0 0 0 0.1875rem rgba(220, 53, 69, 0.25);
}

.form-control.is-invalid ~ .invalid-feedback, .custom-select.is-invalid ~ .invalid-feedback,
.input-wrapper.is-invalid ~ .invalid-feedback, .form-set-label.is-invalid ~ .invalid-feedback {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
  color: var(--x-danger);
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: var(--x-danger);
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-feedback {
  display: block;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.1875rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: var(--x-danger);
}

.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: var(--x-danger);
}

.custom-file-input.is-invalid ~ .invalid-feedback {
  display: block;
}

.custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: var(--x-danger);
  box-shadow: 0 0 0 0.1875rem rgba(220, 53, 69, 0.25);
}

.custom-range {
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  height: calc(var(--x-input-range-thumb-height) + var(--x-input-border-width * 2));
  padding: 0;
  width: 100%;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb,
.custom-range:focus::-moz-range-thumb,
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 0.0625rem var(--x-body-bg-color), 0 0 0 0.1875rem var(--x-input-focus-box-shadow-color);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--x-primary);
  border: 0;
  border-radius: 1rem;
  height: var(--x-input-range-thumb-height);
  margin-top: calc((var(--x-input-range-track-height) - var(--x-input-range-thumb-height)) / 2);
  -webkit-transition: background-color 150ms ease-in-out, border-color 150ms ease-in-out, box-shadow 150ms ease-in-out;
  transition: background-color 150ms ease-in-out, border-color 150ms ease-in-out, box-shadow 150ms ease-in-out;
  width: var(--x-input-range-thumb-width);
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #ffd4a3;
}

.custom-range::-webkit-slider-runnable-track {
  background-color: var(--x-input-range-track-bg-color);
  border-color: transparent;
  border-radius: 1rem;
  color: transparent;
  cursor: pointer;
  height: var(--x-input-range-track-height);
  width: var(--x-input-range-track-width);
}

.custom-range::-moz-range-thumb {
  appearance: none;
  background-color: var(--x-primary);
  border: 0;
  border-radius: 1rem;
  height: var(--x-input-range-thumb-height);
  -moz-transition: background-color 150ms ease-in-out, border-color 150ms ease-in-out, box-shadow 150ms ease-in-out;
  transition: background-color 150ms ease-in-out, border-color 150ms ease-in-out, box-shadow 150ms ease-in-out;
  width: var(--x-input-range-thumb-width);
}

.custom-range::-moz-range-thumb:active {
  background-color: #ffd4a3;
}

.custom-range::-moz-range-track {
  background-color: var(--x-input-range-track-bg-color);
  border-color: transparent;
  border-radius: 1rem;
  color: transparent;
  cursor: pointer;
  height: var(--x-input-range-track-height);
  width: 100%;
}

.custom-range::-ms-thumb {
  appearance: none;
  background-color: var(--x-primary);
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
  height: var(--x-input-range-thumb-height);
  margin-left: var(--x-input-border-width);
  margin-right: var(--x-input-border-width);
  margin-top: 0;
  -ms-transition: background-color 150ms ease-in-out, border-color 150ms ease-in-out, box-shadow 150ms ease-in-out;
  transition: background-color 150ms ease-in-out, border-color 150ms ease-in-out, box-shadow 150ms ease-in-out;
  width: var(--x-input-range-thumb-width);
}

.custom-range::-ms-thumb:active {
  background-color: #ffd4a3;
}

.custom-range::-ms-track {
  background-color: transparent;
  border-color: transparent;
  border-radius: 1rem;
  border-width: var(--x-input-range-thumb-height/2);
  color: transparent;
  cursor: pointer;
  height: var(--x-input-range-track-height);
  width: var(--x-input-range-track-width);
}

.custom-range::-ms-fill-lower {
  background-color: var(--x-nput-range-track-bg-color);
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  background-color: var(--x-input-range-track-bg-color);
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: var(--x-input-range-thumb-disabled-bg-color);
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: var(--x-input-range-thumb-disabled-bg-color);
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: var(--x-input-range-thumb-disabled-bg-color);
}

.container-fluid,
.container-max-fluid,
.container {
  --x-linear-scale-min-value: var(--x-grid-min-gutter-unitless) / 2;
  --x-linear-scale-max-value: var(--x-grid-max-gutter-unitless) / 2;
  --x-slope: (var(--x-linear-scale-max-value) - var(--x-linear-scale-min-value)) / (var(--x-linear-scale-max-width) - var(--x-linear-scale-min-width));
  --x-y-axis-intersection: ((var(--x-linear-scale-min-width) * -1 / 16) * var(--x-slope) + (var(--x-linear-scale-min-value) / 16));
  padding-left: clamp(calc(var(--x-linear-scale-min-value) / 16 * 1rem), calc(var(--x-y-axis-intersection) * 1rem + var(--x-slope) * 100vw), calc(var(--x-linear-scale-max-value) / 16 * 1rem));
  padding-right: clamp(calc(var(--x-linear-scale-min-value) / 16 * 1rem), calc(var(--x-y-axis-intersection) * 1rem + var(--x-slope) * 100vw), calc(var(--x-linear-scale-max-value) / 16 * 1rem));
  margin: 0 auto;
  min-width: var(--x-body-min-width);
  width: 100%;
}

.container-max-fluid {
  max-width: var(--x-container-xl-max-width);
}

.container {
  max-width: 0;
}

@media (min-width: 30rem) {
  .container {
    max-width: 30rem;
  }
}

@media (min-width: 48rem) {
  .container {
    max-width: 48rem;
  }
}

@media (min-width: 62rem) {
  .container {
    max-width: 62rem;
  }
}

@media (min-width: 87.5rem) {
  .container {
    max-width: 87.5rem;
  }
}

.row {
  --x-linear-scale-min-value: var(--x-grid-min-gutter-unitless) / 2;
  --x-linear-scale-max-value: var(--x-grid-max-gutter-unitless) / 2;
  --x-slope: (var(--x-linear-scale-max-value) - var(--x-linear-scale-min-value)) / (var(--x-linear-scale-max-width) - var(--x-linear-scale-min-width));
  --x-y-axis-intersection: ((var(--x-linear-scale-min-width) * -1 / 16) * var(--x-slope) + (var(--x-linear-scale-min-value) / 16));
  margin-left: calc(clamp(calc(var(--x-linear-scale-min-value) / 16 * 1rem), calc(var(--x-y-axis-intersection) * 1rem + var(--x-slope) * 100vw), calc(var(--x-linear-scale-max-value) / 16 * 1rem)) * -1);
  margin-right: calc(clamp(calc(var(--x-linear-scale-min-value) / 16 * 1rem), calc(var(--x-y-axis-intersection) * 1rem + var(--x-slope) * 100vw), calc(var(--x-linear-scale-max-value) / 16 * 1rem)) * -1);
  display: flex;
  flex: 1;
  flex-wrap: wrap;
}

.row > * {
  --x-slope: (var(--x-linear-scale-max-value) - var(--x-linear-scale-min-value)) / (var(--x-linear-scale-max-width) - var(--x-linear-scale-min-width));
  --x-y-axis-intersection: ((var(--x-linear-scale-min-width) * -1 / 16) * var(--x-slope) + (var(--x-linear-scale-min-value) / 16));
  padding-left: clamp(calc(var(--x-linear-scale-min-value) / 16 * 1rem), calc(var(--x-y-axis-intersection) * 1rem + var(--x-slope) * 100vw), calc(var(--x-linear-scale-max-value) / 16 * 1rem));
  padding-right: clamp(calc(var(--x-linear-scale-min-value) / 16 * 1rem), calc(var(--x-y-axis-intersection) * 1rem + var(--x-slope) * 100vw), calc(var(--x-linear-scale-max-value) / 16 * 1rem));
  flex-shrink: 0;
  max-width: 100%;
  position: relative;
  width: 100%;
}

.sm-gutters {
  --x-linear-scale-min-value: var(--x-grid-min-gutter-unitless) / 4;
  --x-linear-scale-max-value: var(--x-grid-max-gutter-unitless) / 4;
  --x-slope: (var(--x-linear-scale-max-value) - var(--x-linear-scale-min-value)) / (var(--x-linear-scale-max-width) - var(--x-linear-scale-min-width));
  --x-y-axis-intersection: ((var(--x-linear-scale-min-width) * -1 / 16) * var(--x-slope) + (var(--x-linear-scale-min-value) / 16));
  margin-left: calc(clamp(calc(var(--x-linear-scale-min-value) / 16 * 1rem), calc(var(--x-y-axis-intersection) * 1rem + var(--x-slope) * 100vw), calc(var(--x-linear-scale-max-value) / 16 * 1rem)) * -1);
  margin-right: calc(clamp(calc(var(--x-linear-scale-min-value) / 16 * 1rem), calc(var(--x-y-axis-intersection) * 1rem + var(--x-slope) * 100vw), calc(var(--x-linear-scale-max-value) / 16 * 1rem)) * -1);
  display: flex;
  flex: 1;
  flex-wrap: wrap;
}

.no-gutters {
  margin-left: 0;
  margin-right: 0;
}

.no-gutters > * {
  padding-left: 0;
  padding-right: 0;
}

.col {
  flex: 1 0 0%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 30rem) {
  .col-sm {
    flex: 1 0 0%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.66667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333%;
  }

  .offset-sm-5 {
    margin-left: 41.66667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333%;
  }

  .offset-sm-8 {
    margin-left: 66.66667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333%;
  }

  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 48rem) {
  .col-md {
    flex: 1 0 0%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.66667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333%;
  }

  .offset-md-5 {
    margin-left: 41.66667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333%;
  }

  .offset-md-8 {
    margin-left: 66.66667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333%;
  }

  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 62rem) {
  .col-lg {
    flex: 1 0 0%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.66667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333%;
  }

  .offset-lg-5 {
    margin-left: 41.66667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333%;
  }

  .offset-lg-8 {
    margin-left: 66.66667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333%;
  }

  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 87.5rem) {
  .col-xl {
    flex: 1 0 0%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.66667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333%;
  }

  .offset-xl-5 {
    margin-left: 41.66667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333%;
  }

  .offset-xl-8 {
    margin-left: 66.66667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333%;
  }

  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

img {
  border-style: none;
  vertical-align: middle;
}

.img-fluid {
  height: auto;
  max-width: 100%;
}

.list-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
}

.list-group-item {
  background-color: var(--x-white);
  border: var(--x-border-width) solid var(--x-gray-400);
  display: block;
  margin-bottom: -0.0625rem;
  padding: 0.5rem 1.25rem;
  position: relative;
}

.list-group-item:first-child {
  border-radius: var(--x-border-radius) var(--x-border-radius) 0 0;
}

.list-group-item:last-child {
  border-radius: 0 0 var(--x-border-radius) var(--x-border-radius);
  margin-bottom: 0;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #868E96;
  pointer-events: none;
}

.list-group-item.active {
  background-color: var(--x-secondary);
  border-color: var(--x-secondary);
  color: #fff;
  z-index: 2;
}

.list-group-flush .list-group-item {
  border-left: 0;
  border-radius: 0;
  border-right: 0;
}

.list-group-flush .list-group-item:last-child {
  margin-bottom: -0.0625rem;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
  margin-bottom: 0;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  display: none;
  height: 100%;
  left: 0;
  min-width: var(--x-body-min-width);
  outline: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 8070;
}

.modal-dialog {
  margin: calc(var(--x-spacer) * 2) var(--x-spacer);
  pointer-events: none;
  position: relative;
  width: auto;
}

.modal.fade .modal-dialog {
  transform: translate(0, -3.125rem);
  transition: transform 300ms ease-out;
}

.modal.show .modal-dialog {
  transform: none;
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - var(--x-spacer) * 4);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - var(--x-spacer) * 4);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  align-items: center;
  display: flex;
  min-height: calc(100% - var(--x-spacer) * 4);
}

.modal-dialog-centered::before {
  content: "";
  display: block;
  height: calc(100vh - var(--x-spacer) * 4);
}

.modal-sm .modal-content {
  max-width: 18.75rem;
}

.modal-md .modal-content {
  max-width: 28.125rem;
}

.modal-lg .modal-content {
  max-width: 37.5rem;
}

.modal-xl .modal-content {
  max-width: 56.25rem;
}

.modal-content {
  background-clip: padding-box;
  background-color: var(--x-white);
  border: var(--x-border-width) solid rgba(0, 0, 0, 0.2);
  border-radius: var(--x-border-radius);
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  outline: 0;
  pointer-events: auto;
  position: relative;
  width: 100%;
}

.modal-backdrop {
  background-color: var(--x-black);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 8060;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.9;
}

@keyframes color-header-animation {
  0% {
    background-position: 22% 0%;
  }

  100% {
    background-position: 100% 0%;
  }
}

.modal-header {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  padding: calc(var(--x-spacer) * 2);
  position: relative;
}

.modal-header .close {
  margin: -0.375rem -0.5rem -0.5rem auto;
  padding: var(--x-spacer);
}

.modal-header::after {
  content: "";
  height: 0.4375rem;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.modal-title {
  line-height: var(--x-line-height-base);
  margin-bottom: 0;
}

.modal-body {
  flex: 1 1 auto;
  padding: calc(var(--x-spacer) * 2);
  position: relative;
}

.modal-footer {
  align-items: center;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-top: 0.0625rem solid var(--x-gray-400);
  display: flex;
  justify-content: flex-end;
  padding: calc(var(--x-spacer) * 2);
}

.modal-footer > :not(:first-child) {
  margin-left: calc(var(--x-spacer) / 2);
}

.modal-footer > :not(:last-child) {
  margin-right: calc(var(--x-spacer) / 2);
}

.modal-scrollbar-measure {
  height: 3.125rem;
  overflow: scroll;
  position: absolute;
  top: -624.9375rem;
  width: 3.125rem;
}

@media (min-width: 62rem) {
  .modal-lg {
    margin-bottom: 1.75rem;
    margin-top: 1.75rem;
  }

  .modal-lg.modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-lg.modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }
}

@media (min-width: 87.5rem) {
  .modal-xl {
    margin-bottom: 1.75rem;
    margin-top: 1.75rem;
  }

  .modal-xl.modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }

  .modal-xl.modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-xl.modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-xl.modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

.nav-link {
  display: block;
  padding: 0.375rem var(--x-spacer);
}

.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: var(--x-gray-600);
  cursor: default;
  pointer-events: none;
}

.nav-pills .nav-link {
  border-radius: var(--x-border-radius);
}

//.nav-pills .nav-link.active {
//  background-color: var(--x-primary);
//  color: var(--x-white);
//  fill: var(--x-white);
//}
//
.nav-tabs {
  border-bottom: var(--x-nav-tabs-border-width) solid var(--x-nav-tabs-border-color);
}

.nav-tabs .nav-item {
  margin-bottom: calc(var(--x-nav-tabs-border-width) * -1);
  margin-left: calc(var(--x-spacer) / 4);
  margin-right: calc(var(--x-spacer) / 4);
}

.nav-tabs .nav-item:first-child {
  margin-left: 0;
}

.nav-tabs .nav-item:last-child {
  margin-right: 0;
}

.nav-tabs .nav-link {
  border: var(--x-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--x-nav-tabs-border-radius);
  border-top-right-radius: var(--x-nav-tabs-border-radius);
}

.nav-tabs .nav-link:hover {
  border-color: var(--x-nav-tabs-link-hover-border-color);
}

.nav-tabs .nav-link.disabled {
  background-color: transparent;
  border-color: transparent;
  color: var(--x-nav-link-disabled-color);
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: var(--x-nav-tabs-link-active-bg-color);
  border-color: var(--x-nav-tabs-link-active-border-color);
  color: var(--x-nav-tabs-link-active-color);
}

.nav-tabs .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: calc(var(--x-nav-tabs-border-width) * -1);
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: var(--x-navbar-padding-y) var(--x-navbar-padding-x);
  position: relative;
}

.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-max-fluid,
.navbar > .container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.navbar-brand {
  display: flex;
  line-height: inherit;
  margin-right: var(--x-navbar-padding-x);
  white-space: nowrap;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

.navbar-nav .nav-link {
  align-items: center;
  display: flex;
  height: 2.25rem;
  padding-left: 0;
  padding-right: 0;
}

.navbar-nav .dropdown-menu {
  float: none;
  position: static;
}

.navbar-nav .active {
  position: relative;
}

.navbar-nav .active::after {
  border-left: 0.1875rem solid var(--x-navbar-active-color);
  bottom: 0;
  content: "";
  left: calc(var(--x-grid-min-gutter) * -1rem / 2);
  position: absolute;
  top: 0;
}

.navbar-header-sm ~ main .table {
  --x-table-fixed-header-offset: 4.125rem;
}

@media (min-width: 30rem) {
  .navbar-header-sm {
    padding-bottom: calc(var(--x-spacer) * 1.5);
  }

  .navbar-header-sm ~ main .table {
    --x-table-fixed-header-offset: 4.875rem;
  }
}

.navbar-header-md ~ main .table {
  --x-table-fixed-header-offset: 4.125rem;
}

@media (min-width: 48rem) {
  .navbar-header-md {
    padding-bottom: calc(var(--x-spacer) * 1.5);
  }

  .navbar-header-md ~ main .table {
    --x-table-fixed-header-offset: 4.875rem;
  }
}

.navbar-header-lg ~ main .table {
  --x-table-fixed-header-offset: 4.125rem;
}

@media (min-width: 62rem) {
  .navbar-header-lg {
    padding-bottom: calc(var(--x-spacer) * 1.5);
  }

  .navbar-header-lg ~ main .table {
    --x-table-fixed-header-offset: 4.875rem;
  }
}

.navbar-header-xl ~ main .table {
  --x-table-fixed-header-offset: 4.125rem;
}

@media (min-width: 87.5rem) {
  .navbar-header-xl {
    padding-bottom: calc(var(--x-spacer) * 1.5);
  }

  .navbar-header-xl ~ main .table {
    --x-table-fixed-header-offset: 4.875rem;
  }
}

.navbar-header {
  padding-bottom: calc(var(--x-spacer) * 1.5);
}

.navbar-header ~ main .table {
  --x-table-fixed-header-offset: 4.125rem;
}

.navbar-header ~ main .table {
  --x-table-fixed-header-offset: 4.875rem;
}

.navbar-header-filter {
  position: relative;
}

.navbar-header-filter::after {
  -webkit-backdrop-filter: saturate(180%) blur(1rem);
  backdrop-filter: saturate(180%) blur(1rem);
  background: rgba(255, 255, 255, 0.3);
  bottom: calc(var(--x-spacer) * -1.5);
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.navbar-text {
  display: inline-block;
  padding-bottom: var(--x-nav-link-padding-y);
  padding-top: var(--x-nav-link-padding-y);
}

.navbar-collapse {
  align-items: center;
  flex-basis: 100%;
  flex-grow: 1;
}

//@media (max-width: xs) {
//  .navbar-expand-sm > .container,
//  .navbar-expand-sm > .container-fluid,
//  .navbar-expand-sm > .container-max-fluid,
//  .navbar-expand-sm > .container {
//    padding-left: 0;
//    padding-right: 0;
//  }
//}
//
@media (min-width: 30rem) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-left: var(--x-navbar-nav-link-padding-x);
    padding-right: var(--x-navbar-nav-link-padding-x);
  }

  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-max-fluid,
  .navbar-expand-sm > .container {
    flex-wrap: nowrap;
  }

  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }

  .navbar-expand-sm .active::after {
    border-bottom: 0.1875rem solid var(--x-navbar-active-color);
    border-left: 0;
    bottom: calc(var(--x-navbar-padding-y) * -1);
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: inherit;
  }
}

//@media (max-width: sm) {
//  .navbar-expand-md > .container,
//  .navbar-expand-md > .container-fluid,
//  .navbar-expand-md > .container-max-fluid,
//  .navbar-expand-md > .container {
//    padding-left: 0;
//    padding-right: 0;
//  }
//}
//
@media (min-width: 48rem) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-left: var(--x-navbar-nav-link-padding-x);
    padding-right: var(--x-navbar-nav-link-padding-x);
  }

  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-max-fluid,
  .navbar-expand-md > .container {
    flex-wrap: nowrap;
  }

  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }

  .navbar-expand-md .active::after {
    border-bottom: 0.1875rem solid var(--x-navbar-active-color);
    border-left: 0;
    bottom: calc(var(--x-navbar-padding-y) * -1);
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: inherit;
  }
}

//@media (max-width: md) {
//  .navbar-expand-lg > .container,
//  .navbar-expand-lg > .container-fluid,
//  .navbar-expand-lg > .container-max-fluid,
//  .navbar-expand-lg > .container {
//    padding-left: 0;
//    padding-right: 0;
//  }
//}
//
@media (min-width: 62rem) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: var(--x-navbar-nav-link-padding-x);
    padding-right: var(--x-navbar-nav-link-padding-x);
  }

  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-max-fluid,
  .navbar-expand-lg > .container {
    flex-wrap: nowrap;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }

  .navbar-expand-lg .active::after {
    border-bottom: 0.1875rem solid var(--x-navbar-active-color);
    border-left: 0;
    bottom: calc(var(--x-navbar-padding-y) * -1);
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: inherit;
  }
}

//@media (max-width: lg) {
//  .navbar-expand-xl > .container,
//  .navbar-expand-xl > .container-fluid,
//  .navbar-expand-xl > .container-max-fluid,
//  .navbar-expand-xl > .container {
//    padding-left: 0;
//    padding-right: 0;
//  }
//}
//
@media (min-width: 87.5rem) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-left: var(--x-navbar-nav-link-padding-x);
    padding-right: var(--x-navbar-nav-link-padding-x);
  }

  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-max-fluid,
  .navbar-expand-xl > .container {
    flex-wrap: nowrap;
  }

  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xl .active::after {
    border-bottom: 0.1875rem solid var(--x-navbar-active-color);
    border-left: 0;
    bottom: calc(var(--x-navbar-padding-y) * -1);
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: inherit;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-max-fluid,
.navbar-expand > .container {
  padding-left: 0;
  padding-right: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-left: var(--x-navbar-nav-link-padding-x);
  padding-right: var(--x-navbar-nav-link-padding-x);
}

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-max-fluid,
.navbar-expand > .container {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .active::after {
  border-bottom: 0.1875rem solid var(--x-navbar-active-color);
  border-left: 0;
  bottom: calc(var(--x-navbar-padding-y) * -1);
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: inherit;
}

.navbar-x-net .navbar-brand {
  color: var(--x-navbar-brand-color);
}

//.navbar-x-net .navbar-brand:focus , .navbar-x-net .navbar-brand:hover {
//  color: var(--x-navbar-brand-hover-color);
//}
//
.navbar-x-net .navbar-nav .nav-link {
  -webkit-text-fill-color: var(--x-navbar-color);
  -webkit-opacity: 1;
  color: var(--x-navbar-color);
  text-transform: uppercase;
}

//.navbar-x-net .navbar-nav .nav-link:focus , .navbar-x-net .navbar-nav .nav-link:hover {
//  color: var(--x-navbar-hover-color);
//}
//
//.navbar-x-net .navbar-nav .nav-link:focus .icon , .navbar-x-net .navbar-nav .nav-link:hover .icon {
//  fill: var(--x-navbar-hover-color);
//}
//
//.navbar-x-net .navbar-nav .nav-link.disabled {
//  color: var(--x-navbar-disabled-color);
//}
//
.navbar-x-net .navbar-nav .show > .nav-link,
.navbar-x-net .navbar-nav .active > .nav-link,
.navbar-x-net .navbar-nav .nav-link.show,
.navbar-x-net .navbar-nav .nav-link.active {
  -webkit-text-fill-color: var(--x-navbar-color);
  -webkit-opacity: 1;
  color: var(--x-navbar-color);
}

.navbar-x-net .navbar-nav .show > .nav-link .icon,
.navbar-x-net .navbar-nav .active > .nav-link .icon,
.navbar-x-net .navbar-nav .nav-link.show .icon,
.navbar-x-net .navbar-nav .nav-link.active .icon {
  fill: var(--x-navbar-color);
}

//.navbar-x-net .navbar-nav .show > .nav-link:focus , .navbar-x-net .navbar-nav .show > .nav-link:hover , .navbar-x-net .navbar-nav .active > .nav-link:focus , .navbar-x-net .navbar-nav .active > .nav-link:hover , .navbar-x-net .navbar-nav .nav-link.show:focus , .navbar-x-net .navbar-nav .nav-link.show:hover , .navbar-x-net .navbar-nav .nav-link.active:focus , .navbar-x-net .navbar-nav .nav-link.active:hover {
//  color: var(--x-navbar-hover-color);
//}
//
//.navbar-x-net .navbar-nav .show > .nav-link:focus .icon , .navbar-x-net .navbar-nav .show > .nav-link:hover .icon , .navbar-x-net .navbar-nav .active > .nav-link:focus .icon , .navbar-x-net .navbar-nav .active > .nav-link:hover .icon , .navbar-x-net .navbar-nav .nav-link.show:focus .icon , .navbar-x-net .navbar-nav .nav-link.show:hover .icon , .navbar-x-net .navbar-nav .nav-link.active:focus .icon , .navbar-x-net .navbar-nav .nav-link.active:hover .icon {
//  fill: var(--x-navbar-hover-color);
//}
//
.navbar-x-net .navbar-toggler-icon-top,
.navbar-x-net .navbar-toggler-icon-middle,
.navbar-x-net .navbar-toggler-icon-bottom {
  background: var(--x-navbar-toggler-color);
}

.navbar-x-net .navbar-text {
  color: var(--x-navbar-color);
}

.navbar-x-net .navbar-text a {
  color: var(--x-navbar-active-color);
}

.navbar-x-net .navbar-text a:focus,
.navbar-x-net .navbar-text a:hover {
  color: var(--x-navbar-active-color);
}

.navbar-x-net .icon {
  fill: var(--x-navbar-color);
}

@keyframes line-top-in {
  0% {
    bottom: 0;
    left: -0.3125rem;
    transform: rotate(-45deg);
  }

  20% {
    bottom: 0;
    left: -0.3125rem;
    transform: rotate(-60deg);
  }

  80% {
    bottom: 0;
    left: 0;
    transform: rotate(10deg);
  }

  100% {
    bottom: 0.0625rem;
    left: 0;
    transform: rotate(0deg);
  }
}

@keyframes line-top-out {
  0% {
    left: 0;
    top: 0;
    transform: rotate(0deg);
  }

  20% {
    left: 0;
    top: 0;
    transform: rotate(10deg);
  }

  80% {
    left: -0.3125rem;
    top: 0;
    transform: rotate(-60deg);
  }

  100% {
    left: -0.3125rem;
    top: 0.0625rem;
    transform: rotate(-45deg);
  }
}

@keyframes line-bot-in {
  0% {
    left: -0.3125rem;
    transform: rotate(45deg);
  }

  20% {
    bottom: 0;
    left: -0.3125rem;
    transform: rotate(60deg);
  }

  80% {
    bottom: 0;
    left: 0;
    transform: rotate(-10deg);
  }

  100% {
    left: 0;
    transform: rotate(0deg);
  }
}

@keyframes line-bot-out {
  0% {
    left: 0;
    transform: rotate(0deg);
  }

  20% {
    left: 0;
    transform: rotate(-10deg);
  }

  80% {
    left: -0.3125rem;
    transform: rotate(60deg);
  }

  100% {
    left: -0.3125rem;
    transform: rotate(45deg);
  }
}

.navbar-toggler {
  background: none;
  border: 0;
  padding: 0.25rem 0;
}

.navbar-toggler:focus {
  outline: 0;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon-top {
  animation: line-top-out 600ms linear normal;
  animation-fill-mode: forwards;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon-middle {
  opacity: 0;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon-bottom {
  animation: line-bot-out 600ms linear normal;
  animation-fill-mode: forwards;
}

.navbar-toggler.collapsed .navbar-toggler-icon-top {
  animation: line-top-in 600ms linear normal;
  animation-fill-mode: forwards;
}

.navbar-toggler.collapsed .navbar-toggler-icon-middle {
  transition-delay: 200ms;
}

.navbar-toggler.collapsed .navbar-toggler-icon-bottom {
  animation: line-bot-in 600ms linear normal;
  animation-fill-mode: forwards;
}

.navbar-toggler-icon {
  cursor: pointer;
  display: block;
  height: 1.625rem;
  position: relative;
  width: 1.875rem;
}

.navbar-toggler-icon-top,
.navbar-toggler-icon-middle,
.navbar-toggler-icon-bottom {
  border-radius: 0.125rem;
  display: block;
  height: 0.25rem;
  position: absolute;
  width: 1.875rem;
}

.navbar-toggler-icon-top {
  top: 0;
  transform-origin: 1.875rem 0.125rem;
}

.navbar-toggler-icon-middle {
  top: 0.6875rem;
  transition: opacity 200ms linear;
}

.navbar-toggler-icon-bottom {
  bottom: 0;
  transform-origin: 1.875rem 0.125rem;
}

.pagination {
  list-style: none;
  padding-left: 0;
  border-radius: var(--x-border-radius);
  display: flex;
}

.page-link {
  background-color: var(--x-pagination-bg-color);
  border: var(--x-border-width) solid var(--x-pagination-border-color);
  color: var(--x-pagination-color);
  cursor: pointer;
  display: block;
  line-height: var(--x-pagination-line-height);
  margin-left: calc(var(--x-border-width) * -1);
  padding: var(--x-pagination-padding-y) var(--x-pagination-padding-x);
  position: relative;
  -webkit-user-select: none;
  user-select: none;
}

.page-link:hover {
  --x-pagination-bg-color: var(--x-pagination-hover-bg-color);
  --x-pagination-border-color: var(--x-pagination-hover-border-color);
  --x-pagination-color: var(--x-pagination-hover-color);
  text-decoration: none;
  z-index: 2;
}

.page-link:focus {
  --x-pagination-border-color: var(--x-pagination-focus-border-color);
  box-shadow: 0 0 0 0.1875rem var(--x-pagination-focus-box-shadow-color);
  outline: 0;
  z-index: 3;
}

.page-item:first-child .page-link {
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  margin-left: 0;
}

.page-item:last-child .page-link {
  border-bottom-right-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.page-item.active .page-link {
  --x-pagination-bg-color: var(--x-pagination-active-bg-color);
  --x-pagination-border-color: var(--x-pagination-active-border-color);
  --x-pagination-color: var(--x-pagination-active-color);
  z-index: 3;
}

.page-item.disabled .page-link {
  --x-pagination-bg-color: var(--x-pagination-disabled-bg-color);
  --x-pagination-border-color: var(--x-pagination-disabled-border-color);
  --x-pagination-color: var(--x-pagination-disabled-color);
  cursor: auto;
  pointer-events: none;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: var(--x-progress-height);
  }
}

.progress {
  background-color: var(--x-progress-bg-color);
  border-radius: var(--x-border-radius);
  display: flex;
  font-size: 0.75rem;
  height: var(--x-progress-height);
  overflow: hidden;
}

.progress-bar {
  background-color: var(--x-progress-bar-bg-color);
  color: var(--x-progress-bar-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  transition: width 600ms ease;
  white-space: nowrap;
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--x-progress-height) var(--x-progress-height);
}

.progress-bar-animated {
  animation: progress-bar-stripes var(--x-progress-bar-animation-timing);
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  animation: spinner-border var(--x-spinner-animation-speed) linear infinite;
  border: var(--x-spinner-border-width) solid var(--x-spinner-color);
  border-radius: 50%;
  border-right-color: transparent;
  display: inline-block;
  height: var(--x-spinner-height);
  vertical-align: text-bottom;
  width: var(--x-spinner-width);
}

.spinner-border-sm {
  border-width: var(--x-spinner-border-width-sm);
  height: var(--x-spinner-height-sm);
  width: var(--x-spinner-width-sm);
}

table {
  border-collapse: collapse;
}

th,
td {
  padding: 0;
  text-align: inherit;
}

.table {
  border-bottom: var(--x-table-border-width) solid var(--x-table-border-color);
  color: var(--x-table-color);
  margin-bottom: calc(var(--x-spacer) * 2);
  max-width: 100%;
  width: 100%;
}

.table > :not(caption) > * > * {
  background-color: var(--x-table-bg-color);
  border-top: var(--x-table-border-width) solid var(--x-table-border-color);
  padding: var(--x-table-cell-padding);
  vertical-align: top;
}

.table > :not(caption) > * > *:first-child {
  padding-left: calc(var(--x-table-cell-padding) * 2);
}

.table > :not(caption) > * > *:last-child {
  padding-right: calc(var(--x-table-cell-padding) * 2);
}

.table > thead > * > th {
  border-bottom: calc(var(--x-table-border-width) * 2) solid var(--x-table-border-color);
  border-top: 0;
  position: relative;
  vertical-align: bottom;
}

.table > tfoot > * td {
  border-top: calc(var(--x-table-border-width) * 2) solid var(--x-table-border-color);
}

.table-sm > :not(caption) > * > * {
  padding: var(--x-table-cell-padding-sm);
}

.table-sm > :not(caption) > * > *:first-child {
  padding-left: calc(var(--x-table-cell-padding-sm) * 2);
}

.table-sm > :not(caption) > * > *:last-child {
  padding-right: calc(var(--x-table-cell-padding-sm) * 2);
}

.table-layout-fixed {
  table-layout: fixed;
}

.table-borderless {
  border: 0;
}

.table-borderless > :not(caption) > * > *,
.table-borderless > thead > * > th {
  border: 0;
}

.table-striped > tbody > tr:nth-of-type(even) > * {
  background-color: var(--x-table-striped-bg-color);
  color: var(--x-table-striped-color);
}

.table-hover > tbody > tr:hover > * {
  background-color: var(--x-table-hover-bg-color);
  color: var(--x-table-hover-color);
}

.table-black {
  --x-table-bg-color: #b8b8b8;
  --x-table-color: #212529;
  --x-table-hover-bg-color: #adadad;
  --x-table-hover-color: #212529;
  --x-table-striped-bg-color: #b0b1b1;
  --x-table-striped-color: #212529;
  --x-table-border-color: #7a7a7a;
}

.table-gray-100 {
  --x-table-bg-color: #fdfdfe;
  --x-table-color: #212529;
  --x-table-hover-bg-color: #ededee;
  --x-table-hover-color: #212529;
  --x-table-striped-bg-color: #f2f2f3;
  --x-table-striped-color: #212529;
  --x-table-border-color: #fbfcfc;
}

.table-gray-200 {
  --x-table-bg-color: #f9fafb;
  --x-table-color: #212529;
  --x-table-hover-bg-color: #e9eaeb;
  --x-table-hover-color: #212529;
  --x-table-striped-bg-color: #eeeff1;
  --x-table-striped-color: #212529;
  --x-table-border-color: #f4f5f7;
}

.table-gray-300 {
  --x-table-bg-color: #f6f7f8;
  --x-table-color: #212529;
  --x-table-hover-bg-color: #e6e7e8;
  --x-table-hover-color: #212529;
  --x-table-striped-bg-color: #ebedee;
  --x-table-striped-color: #212529;
  --x-table-border-color: #eef0f2;
}

.table-gray-400 {
  --x-table-bg-color: #f1f3f5;
  --x-table-color: #212529;
  --x-table-hover-bg-color: #e1e4e6;
  --x-table-hover-color: #212529;
  --x-table-striped-bg-color: #e7e9eb;
  --x-table-striped-color: #212529;
  --x-table-border-color: #e6e9ec;
}

.table-gray-500 {
  --x-table-bg-color: #e8eaed;
  --x-table-color: #212529;
  --x-table-hover-bg-color: #d9dbde;
  --x-table-hover-color: #212529;
  --x-table-striped-bg-color: #dee0e3;
  --x-table-striped-color: #212529;
  --x-table-border-color: #d4d9dd;
}

.table-gray-600 {
  --x-table-bg-color: #dddfe2;
  --x-table-color: #212529;
  --x-table-hover-bg-color: #cfd1d4;
  --x-table-hover-color: #212529;
  --x-table-striped-bg-color: #d4d6d9;
  --x-table-striped-color: #212529;
  --x-table-border-color: #c0c4c8;
}

.table-gray-700 {
  --x-table-bg-color: #ccced0;
  --x-table-color: #212529;
  --x-table-hover-bg-color: #bfc1c3;
  --x-table-hover-color: #212529;
  --x-table-striped-bg-color: #c3c6c8;
  --x-table-striped-color: #212529;
  --x-table-border-color: #a0a4a8;
}

.table-gray-800 {
  --x-table-bg-color: #c6c8ca;
  --x-table-color: #212529;
  --x-table-hover-bg-color: #babcbe;
  --x-table-hover-color: #212529;
  --x-table-striped-bg-color: #bec0c2;
  --x-table-striped-color: #212529;
  --x-table-border-color: #95999c;
}

.table-gray-900 {
  --x-table-bg-color: #c1c2c3;
  --x-table-color: #212529;
  --x-table-hover-bg-color: #b5b6b7;
  --x-table-hover-color: #212529;
  --x-table-striped-bg-color: #b9babb;
  --x-table-striped-color: #212529;
  --x-table-border-color: #8c8e90;
}

.table-danger {
  --x-table-bg-color: #f5c6cb;
  --x-table-color: #212529;
  --x-table-hover-bg-color: #e5babf;
  --x-table-hover-color: #212529;
  --x-table-striped-bg-color: #eabec3;
  --x-table-striped-color: #212529;
  --x-table-border-color: #ed969e;
}

.table-dark {
  --x-table-bg-color: #c1c2c3;
  --x-table-color: #212529;
  --x-table-hover-bg-color: #b5b6b7;
  --x-table-hover-color: #212529;
  --x-table-striped-bg-color: #b9babb;
  --x-table-striped-color: #212529;
  --x-table-border-color: #8c8e90;
}

.table-info {
  --x-table-bg-color: #bee5eb;
  --x-table-color: #212529;
  --x-table-hover-bg-color: #b2d7dc;
  --x-table-hover-color: #212529;
  --x-table-striped-bg-color: #b6dbe1;
  --x-table-striped-color: #212529;
  --x-table-border-color: #86cfda;
}

.table-light {
  --x-table-bg-color: #fdfdfe;
  --x-table-color: #212529;
  --x-table-hover-bg-color: #ededee;
  --x-table-hover-color: #212529;
  --x-table-striped-bg-color: #f2f2f3;
  --x-table-striped-color: #212529;
  --x-table-border-color: #fbfcfc;
}

.table-primary {
  --x-table-bg-color: #fadbb8;
  --x-table-color: #212529;
  --x-table-hover-bg-color: #eacdad;
  --x-table-hover-color: #212529;
  --x-table-striped-bg-color: #efd2b1;
  --x-table-striped-color: #212529;
  --x-table-border-color: #f6bc7b;
}

.table-secondary {
  --x-table-bg-color: #b8d5e1;
  --x-table-color: #212529;
  --x-table-hover-bg-color: #adc8d3;
  --x-table-hover-color: #212529;
  --x-table-striped-bg-color: #b0ccd8;
  --x-table-striped-color: #212529;
  --x-table-border-color: #7ab0c7;
}

.table-tertiary {
  --x-table-bg-color: #b8d5e1;
  --x-table-color: #212529;
  --x-table-hover-bg-color: #adc8d3;
  --x-table-hover-color: #212529;
  --x-table-striped-bg-color: #b0ccd8;
  --x-table-striped-color: #212529;
  --x-table-border-color: #7ab0c7;
}

.table-success {
  --x-table-bg-color: #e0e8c2;
  --x-table-color: #212529;
  --x-table-hover-bg-color: #d2d9b7;
  --x-table-hover-color: #212529;
  --x-table-striped-bg-color: #d6deba;
  --x-table-striped-color: #212529;
  --x-table-border-color: #c5d48e;
}

.table-warning {
  --x-table-bg-color: #ffeeba;
  --x-table-color: #212529;
  --x-table-hover-bg-color: #eedfaf;
  --x-table-hover-color: #212529;
  --x-table-striped-bg-color: #f4e4b3;
  --x-table-striped-color: #212529;
  --x-table-border-color: #ffdf7e;
}

.table-white {
  --x-table-bg-color: #fff;
  --x-table-color: #212529;
  --x-table-hover-bg-color: #eeefef;
  --x-table-hover-color: #212529;
  --x-table-striped-bg-color: #f4f4f4;
  --x-table-striped-color: #212529;
  --x-table-border-color: #fff;
}

//@media (max-width: xs) {
//  .table-responsive-sm {
//    -webkit-overflow-scrolling: touch;
//    overflow-x: auto;
//  }
//
//  .table-responsive-sm > .table-bordered {
//    border: 0;
//  }
//}
//
//@media (max-width: sm) {
//  .table-responsive-md {
//    -webkit-overflow-scrolling: touch;
//    overflow-x: auto;
//  }
//
//  .table-responsive-md > .table-bordered {
//    border: 0;
//  }
//}
//
//@media (max-width: md) {
//  .table-responsive-lg {
//    -webkit-overflow-scrolling: touch;
//    overflow-x: auto;
//  }
//
//  .table-responsive-lg > .table-bordered {
//    border: 0;
//  }
//}
//
//@media (max-width: lg) {
//  .table-responsive-xl {
//    -webkit-overflow-scrolling: touch;
//    overflow-x: auto;
//  }
//
//  .table-responsive-xl > .table-bordered {
//    border: 0;
//  }
//}
//
.table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

.table-responsive > .table-bordered {
  border: 0;
}

.toast-wrapper {
  --x-linear-scale-min-value: var(--x-grid-min-gutter-unitless) / 2;
  --x-linear-scale-max-value: var(--x-grid-max-gutter-unitless) / 2;
  --x-slope: (var(--x-linear-scale-max-value) - var(--x-linear-scale-min-value)) / (var(--x-linear-scale-max-width) - var(--x-linear-scale-min-width));
  --x-y-axis-intersection: ((var(--x-linear-scale-min-width) * -1 / 16) * var(--x-slope) + (var(--x-linear-scale-min-value) / 16));
  //right: clamp(calc(var(--x-linear-scale-min-value) / 16 * 1rem), calc(var(--x-y-axis-intersection) * 1rem + var(--x-slope) * 100vw), calc(var(--x-linear-scale-max-value) / 16 * 1rem));
  //bottom: calc(var(--x-spacer) * 4);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  z-index: 2000;
}

.toast {
  font-size: var(--x-toast-font-size);
  max-width: var(--x-toast-max-width);
  opacity: 0;
  text-align: right;
}

.toast:not(:last-child) {
  margin-bottom: var(--x-toast-padding-x);
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-content {
  background-clip: padding-box;
  background-color: var(--x-toast-background-color);
  //background-color: #5ba775;
  border: var(--x-toast-border-width) solid var(--x-toast-border-color);
  border-radius: var(--x-border-radius);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  display: inline-flex;
  flex-direction: row-reverse;
  overflow: hidden;
  text-align: left;
}

.toast-header {
  align-items: center;
  background-clip: padding-box;
  background-color: var(--x-toast-header-background-color);
  border-bottom: var(--x-toast-border-width) solid var(--x-toast-header-border-color);
  color: var(--x-toast-header-color);
  display: flex;
  padding: var(--x-toast-padding-y) var(--x-toast-padding-x);
}

.toast-body {
  margin-left: auto;
  padding: var(--x-toast-padding-x);
}

.tooltip {
  font-style: normal;
  letter-spacing: normal;
  line-break: auto;
  line-height: var(--x-line-height-base);
  text-align: left;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  display: block;
  font-size: 0.8125rem;
  line-height: 1.3;
  margin: var(--x-tooltip-margin);
  opacity: 0;
  position: absolute;
  word-wrap: break-word;
  z-index: 9000;
}

.tooltip.show {
  opacity: 1;
}

.tooltip .arrow {
  display: block;
  height: var(--x-tooltip-arrow-height);
  position: absolute;
  width: var(--x-tooltip-arrow-width);
}

.tooltip .arrow::before {
  border-color: transparent;
  border-style: solid;
  content: "";
  position: absolute;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
  padding: var(--x-tooltip-arrow-height) 0;
}

.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: var(--x-tooltip-arrow-color);
  border-width: var(--x-tooltip-arrow-height) calc(var(--x-tooltip-arrow-width) / 2) 0;
  top: 0;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^="right"] {
  padding: 0 var(--x-tooltip-arrow-height);
}

.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^="right"] .arrow {
  height: var(--x-tooltip-arrow-width);
  left: 0;
  width: var(--x-tooltip-arrow-height);
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: var(--x-tooltip-arrow-color);
  border-width: calc(var(--x-tooltip-arrow-width) / 2) var(--x-tooltip-arrow-height) calc(var(--x-tooltip-arrow-width) / 2) 0;
  right: 0;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^="bottom"] {
  padding: var(--x-tooltip-arrow-height) 0;
}

.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: var(--x-tooltip-arrow-color);
  border-width: 0 calc(var(--x-tooltip-arrow-width) / 2) var(--x-tooltip-arrow-height);
  bottom: 0;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^="left"] {
  padding: 0 var(--x-tooltip-arrow-height);
}

.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^="left"] .arrow {
  height: var(--x-tooltip-arrow-width);
  right: 0;
  top: var(--x-tooltip-arrow-height);
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: var(--x-tooltip-arrow-color);
  border-width: calc(var(--x-tooltip-arrow-width) / 2) 0 calc(var(--x-tooltip-arrow-width) / 2) var(--x-tooltip-arrow-height);
  left: 0;
}

.tooltip-inner {
  background-color: var(--x-tooltip-arrow-color);
  border-radius: var(--x-border-radius);
  color: #fff;
  max-width: 12.5rem;
  padding: var(--x-tooltip-padding-y) var(--x-tooltip-padding-x);
  text-align: left;
}

.fade {
  transition: opacity 150ms linear;
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  position: relative;
  transition: height 350ms ease;
}

body {
  --x-linear-scale-min-value: var(--x-default-min-font-size);
  --x-linear-scale-max-value: var(--x-default-max-font-size);
  --x-slope: (var(--x-linear-scale-max-value) - var(--x-linear-scale-min-value)) / (var(--x-linear-scale-max-width) - var(--x-linear-scale-min-width));
  --x-y-axis-intersection: ((var(--x-linear-scale-min-width) * -1 / 16) * var(--x-slope) + (var(--x-linear-scale-min-value) / 16));
  font-size: clamp(calc(var(--x-linear-scale-min-value) / 16 * 1rem), calc(var(--x-y-axis-intersection) * 1rem + var(--x-slope) * 100vw), calc(var(--x-linear-scale-max-value) / 16 * 1rem));
  background-color: var(--x-white);
  color: var(--x-body-color);
  font-feature-settings: "liga", "kern";
  font-kerning: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: var(--x-line-height-base);
  margin: 0;
  min-width: var(--x-body-min-width);
  overflow-y: auto;
  -webkit-tap-highlight-color: transparent;
  text-align: left;
  text-rendering: optimizeSpeed;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: var(--x-black);
  line-height: 1.1;
  margin-top: 0;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small {
  font-size: 75%;
}

h1,
.h1 {
  --x-linear-scale-min-value: var(--x-h1-min-font-size);
  --x-linear-scale-max-value: var(--x-h1-max-font-size);
  --x-slope: (var(--x-linear-scale-max-value) - var(--x-linear-scale-min-value)) / (var(--x-linear-scale-max-width) - var(--x-linear-scale-min-width));
  --x-y-axis-intersection: ((var(--x-linear-scale-min-width) * -1 / 16) * var(--x-slope) + (var(--x-linear-scale-min-value) / 16));
  font-size: clamp(calc(var(--x-linear-scale-min-value) / 16 * 1rem), calc(var(--x-y-axis-intersection) * 1rem + var(--x-slope) * 100vw), calc(var(--x-linear-scale-max-value) / 16 * 1rem));
  margin-bottom: calc(var(--x-spacer) * 2);
  //text-transform: uppercase;
}

h2,
.h2 {
  --x-linear-scale-min-value: var(--x-h2-min-font-size);
  --x-linear-scale-max-value: var(--x-h2-max-font-size);
  --x-slope: (var(--x-linear-scale-max-value) - var(--x-linear-scale-min-value)) / (var(--x-linear-scale-max-width) - var(--x-linear-scale-min-width));
  --x-y-axis-intersection: ((var(--x-linear-scale-min-width) * -1 / 16) * var(--x-slope) + (var(--x-linear-scale-min-value) / 16));
  font-size: clamp(calc(var(--x-linear-scale-min-value) / 16 * 1rem), calc(var(--x-y-axis-intersection) * 1rem + var(--x-slope) * 100vw), calc(var(--x-linear-scale-max-value) / 16 * 1rem));
  margin-bottom: var(--x-spacer);
  //text-transform: uppercase;
}

h3,
.h3 {
  --x-linear-scale-min-value: var(--x-h3-min-font-size);
  --x-linear-scale-max-value: var(--x-h3-max-font-size);
  --x-slope: (var(--x-linear-scale-max-value) - var(--x-linear-scale-min-value)) / (var(--x-linear-scale-max-width) - var(--x-linear-scale-min-width));
  --x-y-axis-intersection: ((var(--x-linear-scale-min-width) * -1 / 16) * var(--x-slope) + (var(--x-linear-scale-min-value) / 16));
  font-size: clamp(calc(var(--x-linear-scale-min-value) / 16 * 1rem), calc(var(--x-y-axis-intersection) * 1rem + var(--x-slope) * 100vw), calc(var(--x-linear-scale-max-value) / 16 * 1rem));
  margin-bottom: var(--x-spacer);
}

h4,
.h4 {
  --x-linear-scale-min-value: var(--x-h4-min-font-size);
  --x-linear-scale-max-value: var(--x-h4-max-font-size);
  --x-slope: (var(--x-linear-scale-max-value) - var(--x-linear-scale-min-value)) / (var(--x-linear-scale-max-width) - var(--x-linear-scale-min-width));
  --x-y-axis-intersection: ((var(--x-linear-scale-min-width) * -1 / 16) * var(--x-slope) + (var(--x-linear-scale-min-value) / 16));
  font-size: clamp(calc(var(--x-linear-scale-min-value) / 16 * 1rem), calc(var(--x-y-axis-intersection) * 1rem + var(--x-slope) * 100vw), calc(var(--x-linear-scale-max-value) / 16 * 1rem));
  line-height: var(--x-line-height-base);
  margin-bottom: var(--x-spacer);
}

h5,
.h5 {
  --x-linear-scale-min-value: var(--x-h5-min-font-size);
  --x-linear-scale-max-value: var(--x-h5-max-font-size);
  --x-slope: (var(--x-linear-scale-max-value) - var(--x-linear-scale-min-value)) / (var(--x-linear-scale-max-width) - var(--x-linear-scale-min-width));
  --x-y-axis-intersection: ((var(--x-linear-scale-min-width) * -1 / 16) * var(--x-slope) + (var(--x-linear-scale-min-value) / 16));
  font-size: clamp(calc(var(--x-linear-scale-min-value) / 16 * 1rem), calc(var(--x-y-axis-intersection) * 1rem + var(--x-slope) * 100vw), calc(var(--x-linear-scale-max-value) / 16 * 1rem));
  line-height: var(--x-line-height-base);
  margin-bottom: var(--x-spacer);
}

p {
  margin-bottom: 0.875rem;
  margin-top: 0;
}

a {
  background-color: transparent;
  color: var(--x-anchor-color);
  fill: var(--x-anchor-color);
  text-decoration: none;
}

a:focus {
  outline: 0.125rem solid var(--x-anchor-focus-color);
  outline-offset: 0.0625rem;
  position: relative;
  text-decoration: none;
  z-index: 3000;
}

a:hover {
  --x-anchor-color: var(--x-anchor-hover-color);
}

a.disabled {
  opacity: 0.65;
  pointer-events: none;
}

a[tabindex="-1"],
a[data-focus-method="mouse"],
a[data-focus-method="touch"] {
  outline: 0;
}

a:not([href]) {
  --x-anchor-color: var(--x-body-color);
}

hr {
  border: 0;
  border-top: var(--x-border-width) solid var(--x-gray-400);
  box-sizing: content-box;
  height: 0;
  margin-bottom: 1rem;
  margin-top: 1rem;
  overflow: visible;
}

small,
.small {
  font-size: var(--x-small-font-size);
}

abbr[title],
abbr[data-original-title] {
  border-bottom: 0;
  cursor: help;
  text-decoration: none;
}

ol,
ul {
  margin-bottom: 1rem;
  margin-top: 0;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}

dl {
  margin-bottom: 1rem;
  margin-top: 0;
}

.list-styled {
  list-style: url("data:image/svg+xml;utf8,%3csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10'%3e%3crect fill='%23EE7F01' height='7' width='7' x='0' y='1'/%3e%3c/svg%3e");
  margin-left: 0;
  padding-left: 1.0625rem;
}

.list-unstyled {
  list-style: none;
  padding-left: 0;
}

sub,
sup {
  font-size: var(--x-sub-sup-font-size);
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.4375em;
}

sup {
  top: -0.25em;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-black {
  background-color: #000 !important;
}

.bg-gray-100 {
  background-color: #F8F9FA !important;
}

.bg-gray-200 {
  background-color: #E9ECEF !important;
}

.bg-gray-300 {
  background-color: #DEE2E6 !important;
}

.bg-gray-400 {
  background-color: #CED4DA !important;
}

.bg-gray-500 {
  background-color: #ADB5BD !important;
}

.bg-gray-600 {
  background-color: #868E96 !important;
}

.bg-gray-700 {
  background-color: #495057 !important;
}

.bg-gray-800 {
  background-color: #343A40 !important;
}

.bg-gray-900 {
  background-color: #212529 !important;
}

.bg-danger {
  background-color: #DC3545 !important;
}

.bg-dark {
  background-color: #212529 !important;
}

.bg-info {
  background-color: #17A2B8 !important;
}

.bg-light {
  background-color: #F8F9FA !important;
}

.bg-primary {
  background-color: #EE7F01 !important;
}

.bg-secondary {
  background-color: #006893 !important;
}

.bg-tertiary {
  background-color: #006893 !important;
}

.bg-success {
  background-color: #90AD25 !important;
}

.bg-warning {
  background-color: #FFC107 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.no-border {
  border: 0 !important;
}

.border {
  border: var(--x-border-width) solid var(--x-gray-500) !important;
}

.border-top {
  border-top: var(--x-border-width) solid var(--x-gray-500) !important;
}

.border-right {
  border-right: var(--x-border-width) solid var(--x-gray-500) !important;
}

.border-bottom {
  border-bottom: var(--x-border-width) solid var(--x-gray-500) !important;
}

.border-left {
  border-left: var(--x-border-width) solid var(--x-gray-500) !important;
}

.border-black {
  border-color: #000 !important;
}

.border-gray-100 {
  border-color: #F8F9FA !important;
}

.border-gray-200 {
  border-color: #E9ECEF !important;
}

.border-gray-300 {
  border-color: #DEE2E6 !important;
}

.border-gray-400 {
  border-color: #CED4DA !important;
}

.border-gray-500 {
  border-color: #ADB5BD !important;
}

.border-gray-600 {
  border-color: #868E96 !important;
}

.border-gray-700 {
  border-color: #495057 !important;
}

.border-gray-800 {
  border-color: #343A40 !important;
}

.border-gray-900 {
  border-color: #212529 !important;
}

.border-danger {
  border-color: #DC3545 !important;
}

.border-dark {
  border-color: #212529 !important;
}

.border-info {
  border-color: #17A2B8 !important;
}

.border-light {
  border-color: #F8F9FA !important;
}

.border-primary {
  border-color: #EE7F01 !important;
}

.border-secondary {
  border-color: #006893 !important;
}

.border-tertiary {
  border-color: #006893 !important;
}

.border-success {
  border-color: #90AD25 !important;
}

.border-warning {
  border-color: #FFC107 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded {
  border-radius: var(--x-border-radius) !important;
}

.rounded-top {
  border-top-left-radius: var(--x-border-radius) !important;
  border-top-right-radius: var(--x-border-radius) !important;
}

.rounded-top-left {
  border-top-left-radius: var(--x-border-radius) !important;
}

.rounded-top-right {
  border-top-right-radius: var(--x-border-radius) !important;
}

.rounded-right {
  border-bottom-right-radius: var(--x-border-radius) !important;
  border-top-right-radius: var(--x-border-radius) !important;
}

.rounded-bottom {
  border-bottom-left-radius: var(--x-border-radius) !important;
  border-bottom-right-radius: var(--x-border-radius) !important;
}

.rounded-bottom-left {
  border-bottom-left-radius: var(--x-border-radius) !important;
}

.rounded-bottom-right {
  border-bottom-right-radius: var(--x-border-radius) !important;
}

.rounded-left {
  border-bottom-left-radius: var(--x-border-radius) !important;
  border-top-left-radius: var(--x-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.text-left {
  text-align: left !important;
}

.rounded {
  border-radius: var(--x-border-radius) !important;
}

.rounded-top {
  border-top-left-radius: var(--x-border-radius) !important;
  border-top-right-radius: var(--x-border-radius) !important;
}

.roundedtop-left {
  border-top-left-radius: var(--x-border-radius) !important;
}

.rounded-top-right {
  border-top-right-radius: var(--x-border-radius) !important;
}

.rounded-right {
  border-bottom-right-radius: var(--x-border-radius) !important;
  border-top-right-radius: var(--x-border-radius) !important;
}

.rounded-bottom {
  border-bottom-left-radius: var(--x-border-radius) !important;
  border-bottom-right-radius: var(--x-border-radius) !important;
}

.rounded-bottom-left {
  border-bottom-left-radius: var(--x-border-radius) !important;
}

.rounded-bottom-right {
  border-bottom-right-radius: var(--x-border-radius) !important;
}

.rounded-left {
  border-bottom-left-radius: var(--x-border-radius) !important;
  border-top-left-radius: var(--x-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

@media (min-width: 30rem) {
  .text-sm-left {
    text-align: left !important;
  }

  .rounded-sm {
    border-radius: var(--x-border-radius) !important;
  }

  .rounded-sm-top {
    border-top-left-radius: var(--x-border-radius) !important;
    border-top-right-radius: var(--x-border-radius) !important;
  }

  .rounded-smtop-left {
    border-top-left-radius: var(--x-border-radius) !important;
  }

  .rounded-sm-top-right {
    border-top-right-radius: var(--x-border-radius) !important;
  }

  .rounded-sm-right {
    border-bottom-right-radius: var(--x-border-radius) !important;
    border-top-right-radius: var(--x-border-radius) !important;
  }

  .rounded-sm-bottom {
    border-bottom-left-radius: var(--x-border-radius) !important;
    border-bottom-right-radius: var(--x-border-radius) !important;
  }

  .rounded-sm-bottom-left {
    border-bottom-left-radius: var(--x-border-radius) !important;
  }

  .rounded-sm-bottom-right {
    border-bottom-right-radius: var(--x-border-radius) !important;
  }

  .rounded-sm-left {
    border-bottom-left-radius: var(--x-border-radius) !important;
    border-top-left-radius: var(--x-border-radius) !important;
  }

  .rounded-sm-0 {
    border-radius: 0 !important;
  }
}

@media (min-width: 48rem) {
  .text-md-left {
    text-align: left !important;
  }

  .rounded-md {
    border-radius: var(--x-border-radius) !important;
  }

  .rounded-md-top {
    border-top-left-radius: var(--x-border-radius) !important;
    border-top-right-radius: var(--x-border-radius) !important;
  }

  .rounded-mdtop-left {
    border-top-left-radius: var(--x-border-radius) !important;
  }

  .rounded-md-top-right {
    border-top-right-radius: var(--x-border-radius) !important;
  }

  .rounded-md-right {
    border-bottom-right-radius: var(--x-border-radius) !important;
    border-top-right-radius: var(--x-border-radius) !important;
  }

  .rounded-md-bottom {
    border-bottom-left-radius: var(--x-border-radius) !important;
    border-bottom-right-radius: var(--x-border-radius) !important;
  }

  .rounded-md-bottom-left {
    border-bottom-left-radius: var(--x-border-radius) !important;
  }

  .rounded-md-bottom-right {
    border-bottom-right-radius: var(--x-border-radius) !important;
  }

  .rounded-md-left {
    border-bottom-left-radius: var(--x-border-radius) !important;
    border-top-left-radius: var(--x-border-radius) !important;
  }

  .rounded-md-0 {
    border-radius: 0 !important;
  }
}

@media (min-width: 62rem) {
  .text-lg-left {
    text-align: left !important;
  }

  .rounded-lg {
    border-radius: var(--x-border-radius) !important;
  }

  .rounded-lg-top {
    border-top-left-radius: var(--x-border-radius) !important;
    border-top-right-radius: var(--x-border-radius) !important;
  }

  .rounded-lgtop-left {
    border-top-left-radius: var(--x-border-radius) !important;
  }

  .rounded-lg-top-right {
    border-top-right-radius: var(--x-border-radius) !important;
  }

  .rounded-lg-right {
    border-bottom-right-radius: var(--x-border-radius) !important;
    border-top-right-radius: var(--x-border-radius) !important;
  }

  .rounded-lg-bottom {
    border-bottom-left-radius: var(--x-border-radius) !important;
    border-bottom-right-radius: var(--x-border-radius) !important;
  }

  .rounded-lg-bottom-left {
    border-bottom-left-radius: var(--x-border-radius) !important;
  }

  .rounded-lg-bottom-right {
    border-bottom-right-radius: var(--x-border-radius) !important;
  }

  .rounded-lg-left {
    border-bottom-left-radius: var(--x-border-radius) !important;
    border-top-left-radius: var(--x-border-radius) !important;
  }

  .rounded-lg-0 {
    border-radius: 0 !important;
  }
}

@media (min-width: 87.5rem) {
  .text-xl-left {
    text-align: left !important;
  }

  .rounded-xl {
    border-radius: var(--x-border-radius) !important;
  }

  .rounded-xl-top {
    border-top-left-radius: var(--x-border-radius) !important;
    border-top-right-radius: var(--x-border-radius) !important;
  }

  .rounded-xltop-left {
    border-top-left-radius: var(--x-border-radius) !important;
  }

  .rounded-xl-top-right {
    border-top-right-radius: var(--x-border-radius) !important;
  }

  .rounded-xl-right {
    border-bottom-right-radius: var(--x-border-radius) !important;
    border-top-right-radius: var(--x-border-radius) !important;
  }

  .rounded-xl-bottom {
    border-bottom-left-radius: var(--x-border-radius) !important;
    border-bottom-right-radius: var(--x-border-radius) !important;
  }

  .rounded-xl-bottom-left {
    border-bottom-left-radius: var(--x-border-radius) !important;
  }

  .rounded-xl-bottom-right {
    border-bottom-right-radius: var(--x-border-radius) !important;
  }

  .rounded-xl-left {
    border-bottom-left-radius: var(--x-border-radius) !important;
    border-top-left-radius: var(--x-border-radius) !important;
  }

  .rounded-xl-0 {
    border-radius: 0 !important;
  }
}

.cursor-alias {
  cursor: alias;
}

.cursor-na {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-zoom-in {
  cursor: zoom-in;
}

.cursor-zoom-out {
  cursor: zoom-out;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 30rem) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 48rem) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 62rem) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 87.5rem) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

[hidden] {
  display: none !important;
}

.embed-responsive {
  display: block;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;
}

.embed-responsive::before {
  content: "";
  display: block;
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  border: 0;
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 30rem) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 48rem) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 62rem) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 87.5rem) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.icon {
  display: block;
  height: 1.5rem;
  width: 1.5rem;
}

.icon-sm {
  height: 1rem;
  width: 1rem;
}

th.icon-table {
  width: calc(var(--x-table-cell-padding-sm) * 2 + 1.5rem);
}

.icon-black {
  fill: #000 !important;
}

.icon-gray-100 {
  fill: #F8F9FA !important;
}

.icon-gray-200 {
  fill: #E9ECEF !important;
}

.icon-gray-300 {
  fill: #DEE2E6 !important;
}

.icon-gray-400 {
  fill: #CED4DA !important;
}

.icon-gray-500 {
  fill: #ADB5BD !important;
}

.icon-gray-600 {
  fill: #868E96 !important;
}

.icon-gray-700 {
  fill: #495057 !important;
}

.icon-gray-800 {
  fill: #343A40 !important;
}

.icon-gray-900 {
  fill: #212529 !important;
}

.icon-danger {
  fill: #DC3545 !important;
}

.icon-dark {
  fill: #212529 !important;
}

.icon-info {
  fill: #17A2B8 !important;
}

.icon-light {
  fill: #F8F9FA !important;
}

.icon-primary {
  fill: #EE7F01 !important;
}

.icon-secondary {
  fill: #006893 !important;
}

.icon-tertiary {
  fill: #006893 !important;
}

.icon-success {
  fill: #90AD25 !important;
}

.icon-warning {
  fill: #FFC107 !important;
}

.icon-white {
  fill: #fff !important;
}

[aria-expanded="false"] .icon-down {
  display: block !important;
}

[aria-expanded="false"] .icon-up {
  display: none !important;
}

[aria-expanded="true"] .icon-down {
  display: none !important;
}

[aria-expanded="true"] .icon-up {
  display: block !important;
}

.fixed-top {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 8030;
}

.fixed-bottom {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 8030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 8020;
  }
}

.sr-only {
  border: 0 !important;
  clip: rect(0, 0, 0, 0) !important;
  height: 0.0625rem !important;
  margin: -0.0625rem !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 0.0625rem !important;
}

.mt-fluid {
  --x-linear-scale-min-value: var(--x-grid-min-gutter-unitless) / 2;
  --x-linear-scale-max-value: var(--x-grid-max-gutter-unitless) / 2;
  --x-slope: (var(--x-linear-scale-max-value) - var(--x-linear-scale-min-value)) / (var(--x-linear-scale-max-width) - var(--x-linear-scale-min-width));
  --x-y-axis-intersection: ((var(--x-linear-scale-min-width) * -1 / 16) * var(--x-slope) + (var(--x-linear-scale-min-value) / 16));
  margin-top: clamp(calc(var(--x-linear-scale-min-value) / 16 * 1rem), calc(var(--x-y-axis-intersection) * 1rem + var(--x-slope) * 100vw), calc(var(--x-linear-scale-max-value) / 16 * 1rem));
}

.mr-fluid {
  --x-linear-scale-min-value: var(--x-grid-min-gutter-unitless) / 2;
  --x-linear-scale-max-value: var(--x-grid-max-gutter-unitless) / 2;
  --x-slope: (var(--x-linear-scale-max-value) - var(--x-linear-scale-min-value)) / (var(--x-linear-scale-max-width) - var(--x-linear-scale-min-width));
  --x-y-axis-intersection: ((var(--x-linear-scale-min-width) * -1 / 16) * var(--x-slope) + (var(--x-linear-scale-min-value) / 16));
  margin-right: clamp(calc(var(--x-linear-scale-min-value) / 16 * 1rem), calc(var(--x-y-axis-intersection) * 1rem + var(--x-slope) * 100vw), calc(var(--x-linear-scale-max-value) / 16 * 1rem));
}

.mb-fluid {
  --x-linear-scale-min-value: var(--x-grid-min-gutter-unitless) / 2;
  --x-linear-scale-max-value: var(--x-grid-max-gutter-unitless) / 2;
  --x-slope: (var(--x-linear-scale-max-value) - var(--x-linear-scale-min-value)) / (var(--x-linear-scale-max-width) - var(--x-linear-scale-min-width));
  --x-y-axis-intersection: ((var(--x-linear-scale-min-width) * -1 / 16) * var(--x-slope) + (var(--x-linear-scale-min-value) / 16));
  margin-bottom: clamp(calc(var(--x-linear-scale-min-value) / 16 * 1rem), calc(var(--x-y-axis-intersection) * 1rem + var(--x-slope) * 100vw), calc(var(--x-linear-scale-max-value) / 16 * 1rem));
}

.ml-fluid {
  --x-linear-scale-min-value: var(--x-grid-min-gutter-unitless) / 2;
  --x-linear-scale-max-value: var(--x-grid-max-gutter-unitless) / 2;
  --x-slope: (var(--x-linear-scale-max-value) - var(--x-linear-scale-min-value)) / (var(--x-linear-scale-max-width) - var(--x-linear-scale-min-width));
  --x-y-axis-intersection: ((var(--x-linear-scale-min-width) * -1 / 16) * var(--x-slope) + (var(--x-linear-scale-min-value) / 16));
  margin-left: clamp(calc(var(--x-linear-scale-min-value) / 16 * 1rem), calc(var(--x-y-axis-intersection) * 1rem + var(--x-slope) * 100vw), calc(var(--x-linear-scale-max-value) / 16 * 1rem));
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: var(--x-spacer) !important;
}

.mt-1,
.my-1 {
  margin-top: var(--x-spacer) !important;
}

.mr-1,
.mx-1 {
  margin-right: var(--x-spacer) !important;
}

.mb-1,
.my-1 {
  margin-bottom: var(--x-spacer) !important;
}

.ml-1,
.mx-1 {
  margin-left: var(--x-spacer) !important;
}

.m-2 {
  margin: calc(var(--x-spacer) * 2) !important;
}

.mt-2,
.my-2 {
  margin-top: calc(var(--x-spacer) * 2) !important;
}

.mr-2,
.mx-2 {
  margin-right: calc(var(--x-spacer) * 2) !important;
}

.mb-2,
.my-2 {
  margin-bottom: calc(var(--x-spacer) * 2) !important;
}

.ml-2,
.mx-2 {
  margin-left: calc(var(--x-spacer) * 2) !important;
}

.m-3 {
  margin: calc(var(--x-spacer) * 4) !important;
}

.mt-3,
.my-3 {
  margin-top: calc(var(--x-spacer) * 4) !important;
}

.mr-3,
.mx-3 {
  margin-right: calc(var(--x-spacer) * 4) !important;
}

.mb-3,
.my-3 {
  margin-bottom: calc(var(--x-spacer) * 4) !important;
}

.ml-3,
.mx-3 {
  margin-left: calc(var(--x-spacer) * 4) !important;
}

.m-4 {
  margin: calc(var(--x-spacer) * 6) !important;
}

.mt-4,
.my-4 {
  margin-top: calc(var(--x-spacer) * 6) !important;
}

.mr-4,
.mx-4 {
  margin-right: calc(var(--x-spacer) * 6) !important;
}

.mb-4,
.my-4 {
  margin-bottom: calc(var(--x-spacer) * 6) !important;
}

.ml-4,
.mx-4 {
  margin-left: calc(var(--x-spacer) * 6) !important;
}

.m-5 {
  margin: calc(var(--x-spacer) * 8) !important;
}

.mt-5,
.my-5 {
  margin-top: calc(var(--x-spacer) * 8) !important;
}

.mr-5,
.mx-5 {
  margin-right: calc(var(--x-spacer) * 8) !important;
}

.mb-5,
.my-5 {
  margin-bottom: calc(var(--x-spacer) * 8) !important;
}

.ml-5,
.mx-5 {
  margin-left: calc(var(--x-spacer) * 8) !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: var(--x-spacer) !important;
}

.pt-1,
.py-1 {
  padding-top: var(--x-spacer) !important;
}

.pr-1,
.px-1 {
  padding-right: var(--x-spacer) !important;
}

.pb-1,
.py-1 {
  padding-bottom: var(--x-spacer) !important;
}

.pl-1,
.px-1 {
  padding-left: var(--x-spacer) !important;
}

.p-2 {
  padding: calc(var(--x-spacer) * 2) !important;
}

.pt-2,
.py-2 {
  padding-top: calc(var(--x-spacer) * 2) !important;
}

.pr-2,
.px-2 {
  padding-right: calc(var(--x-spacer) * 2) !important;
}

.pb-2,
.py-2 {
  padding-bottom: calc(var(--x-spacer) * 2) !important;
}

.pl-2,
.px-2 {
  padding-left: calc(var(--x-spacer) * 2) !important;
}

.p-3 {
  padding: calc(var(--x-spacer) * 4) !important;
}

.pt-3,
.py-3 {
  padding-top: calc(var(--x-spacer) * 4) !important;
}

.pr-3,
.px-3 {
  padding-right: calc(var(--x-spacer) * 4) !important;
}

.pb-3,
.py-3 {
  padding-bottom: calc(var(--x-spacer) * 4) !important;
}

.pl-3,
.px-3 {
  padding-left: calc(var(--x-spacer) * 4) !important;
}

.p-4 {
  padding: calc(var(--x-spacer) * 6) !important;
}

.pt-4,
.py-4 {
  padding-top: calc(var(--x-spacer) * 6) !important;
}

.pr-4,
.px-4 {
  padding-right: calc(var(--x-spacer) * 6) !important;
}

.pb-4,
.py-4 {
  padding-bottom: calc(var(--x-spacer) * 6) !important;
}

.pl-4,
.px-4 {
  padding-left: calc(var(--x-spacer) * 6) !important;
}

.p-5 {
  padding: calc(var(--x-spacer) * 8) !important;
}

.pt-5,
.py-5 {
  padding-top: calc(var(--x-spacer) * 8) !important;
}

.pr-5,
.px-5 {
  padding-right: calc(var(--x-spacer) * 8) !important;
}

.pb-5,
.py-5 {
  padding-bottom: calc(var(--x-spacer) * 8) !important;
}

.pl-5,
.px-5 {
  padding-left: calc(var(--x-spacer) * 8) !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 30rem) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: var(--x-spacer) !important;
  }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: var(--x-spacer) !important;
  }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: var(--x-spacer) !important;
  }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: var(--x-spacer) !important;
  }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: var(--x-spacer) !important;
  }

  .m-sm-2 {
    margin: calc(var(--x-spacer) * 2) !important;
  }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: calc(var(--x-spacer) * 2) !important;
  }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: calc(var(--x-spacer) * 2) !important;
  }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: calc(var(--x-spacer) * 2) !important;
  }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: calc(var(--x-spacer) * 2) !important;
  }

  .m-sm-3 {
    margin: calc(var(--x-spacer) * 4) !important;
  }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: calc(var(--x-spacer) * 4) !important;
  }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: calc(var(--x-spacer) * 4) !important;
  }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: calc(var(--x-spacer) * 4) !important;
  }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: calc(var(--x-spacer) * 4) !important;
  }

  .m-sm-4 {
    margin: calc(var(--x-spacer) * 6) !important;
  }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: calc(var(--x-spacer) * 6) !important;
  }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: calc(var(--x-spacer) * 6) !important;
  }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: calc(var(--x-spacer) * 6) !important;
  }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: calc(var(--x-spacer) * 6) !important;
  }

  .m-sm-5 {
    margin: calc(var(--x-spacer) * 8) !important;
  }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: calc(var(--x-spacer) * 8) !important;
  }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: calc(var(--x-spacer) * 8) !important;
  }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: calc(var(--x-spacer) * 8) !important;
  }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: calc(var(--x-spacer) * 8) !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: var(--x-spacer) !important;
  }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: var(--x-spacer) !important;
  }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: var(--x-spacer) !important;
  }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: var(--x-spacer) !important;
  }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: var(--x-spacer) !important;
  }

  .p-sm-2 {
    padding: calc(var(--x-spacer) * 2) !important;
  }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: calc(var(--x-spacer) * 2) !important;
  }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: calc(var(--x-spacer) * 2) !important;
  }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: calc(var(--x-spacer) * 2) !important;
  }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: calc(var(--x-spacer) * 2) !important;
  }

  .p-sm-3 {
    padding: calc(var(--x-spacer) * 4) !important;
  }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: calc(var(--x-spacer) * 4) !important;
  }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: calc(var(--x-spacer) * 4) !important;
  }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: calc(var(--x-spacer) * 4) !important;
  }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: calc(var(--x-spacer) * 4) !important;
  }

  .p-sm-4 {
    padding: calc(var(--x-spacer) * 6) !important;
  }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: calc(var(--x-spacer) * 6) !important;
  }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: calc(var(--x-spacer) * 6) !important;
  }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: calc(var(--x-spacer) * 6) !important;
  }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: calc(var(--x-spacer) * 6) !important;
  }

  .p-sm-5 {
    padding: calc(var(--x-spacer) * 8) !important;
  }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: calc(var(--x-spacer) * 8) !important;
  }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: calc(var(--x-spacer) * 8) !important;
  }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: calc(var(--x-spacer) * 8) !important;
  }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: calc(var(--x-spacer) * 8) !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 48rem) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: var(--x-spacer) !important;
  }

  .mt-md-1,
  .my-md-1 {
    margin-top: var(--x-spacer) !important;
  }

  .mr-md-1,
  .mx-md-1 {
    margin-right: var(--x-spacer) !important;
  }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: var(--x-spacer) !important;
  }

  .ml-md-1,
  .mx-md-1 {
    margin-left: var(--x-spacer) !important;
  }

  .m-md-2 {
    margin: calc(var(--x-spacer) * 2) !important;
  }

  .mt-md-2,
  .my-md-2 {
    margin-top: calc(var(--x-spacer) * 2) !important;
  }

  .mr-md-2,
  .mx-md-2 {
    margin-right: calc(var(--x-spacer) * 2) !important;
  }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: calc(var(--x-spacer) * 2) !important;
  }

  .ml-md-2,
  .mx-md-2 {
    margin-left: calc(var(--x-spacer) * 2) !important;
  }

  .m-md-3 {
    margin: calc(var(--x-spacer) * 4) !important;
  }

  .mt-md-3,
  .my-md-3 {
    margin-top: calc(var(--x-spacer) * 4) !important;
  }

  .mr-md-3,
  .mx-md-3 {
    margin-right: calc(var(--x-spacer) * 4) !important;
  }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: calc(var(--x-spacer) * 4) !important;
  }

  .ml-md-3,
  .mx-md-3 {
    margin-left: calc(var(--x-spacer) * 4) !important;
  }

  .m-md-4 {
    margin: calc(var(--x-spacer) * 6) !important;
  }

  .mt-md-4,
  .my-md-4 {
    margin-top: calc(var(--x-spacer) * 6) !important;
  }

  .mr-md-4,
  .mx-md-4 {
    margin-right: calc(var(--x-spacer) * 6) !important;
  }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: calc(var(--x-spacer) * 6) !important;
  }

  .ml-md-4,
  .mx-md-4 {
    margin-left: calc(var(--x-spacer) * 6) !important;
  }

  .m-md-5 {
    margin: calc(var(--x-spacer) * 8) !important;
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: calc(var(--x-spacer) * 8) !important;
  }

  .mr-md-5,
  .mx-md-5 {
    margin-right: calc(var(--x-spacer) * 8) !important;
  }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: calc(var(--x-spacer) * 8) !important;
  }

  .ml-md-5,
  .mx-md-5 {
    margin-left: calc(var(--x-spacer) * 8) !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: var(--x-spacer) !important;
  }

  .pt-md-1,
  .py-md-1 {
    padding-top: var(--x-spacer) !important;
  }

  .pr-md-1,
  .px-md-1 {
    padding-right: var(--x-spacer) !important;
  }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: var(--x-spacer) !important;
  }

  .pl-md-1,
  .px-md-1 {
    padding-left: var(--x-spacer) !important;
  }

  .p-md-2 {
    padding: calc(var(--x-spacer) * 2) !important;
  }

  .pt-md-2,
  .py-md-2 {
    padding-top: calc(var(--x-spacer) * 2) !important;
  }

  .pr-md-2,
  .px-md-2 {
    padding-right: calc(var(--x-spacer) * 2) !important;
  }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: calc(var(--x-spacer) * 2) !important;
  }

  .pl-md-2,
  .px-md-2 {
    padding-left: calc(var(--x-spacer) * 2) !important;
  }

  .p-md-3 {
    padding: calc(var(--x-spacer) * 4) !important;
  }

  .pt-md-3,
  .py-md-3 {
    padding-top: calc(var(--x-spacer) * 4) !important;
  }

  .pr-md-3,
  .px-md-3 {
    padding-right: calc(var(--x-spacer) * 4) !important;
  }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: calc(var(--x-spacer) * 4) !important;
  }

  .pl-md-3,
  .px-md-3 {
    padding-left: calc(var(--x-spacer) * 4) !important;
  }

  .p-md-4 {
    padding: calc(var(--x-spacer) * 6) !important;
  }

  .pt-md-4,
  .py-md-4 {
    padding-top: calc(var(--x-spacer) * 6) !important;
  }

  .pr-md-4,
  .px-md-4 {
    padding-right: calc(var(--x-spacer) * 6) !important;
  }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: calc(var(--x-spacer) * 6) !important;
  }

  .pl-md-4,
  .px-md-4 {
    padding-left: calc(var(--x-spacer) * 6) !important;
  }

  .p-md-5 {
    padding: calc(var(--x-spacer) * 8) !important;
  }

  .pt-md-5,
  .py-md-5 {
    padding-top: calc(var(--x-spacer) * 8) !important;
  }

  .pr-md-5,
  .px-md-5 {
    padding-right: calc(var(--x-spacer) * 8) !important;
  }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: calc(var(--x-spacer) * 8) !important;
  }

  .pl-md-5,
  .px-md-5 {
    padding-left: calc(var(--x-spacer) * 8) !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 62rem) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: var(--x-spacer) !important;
  }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: var(--x-spacer) !important;
  }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: var(--x-spacer) !important;
  }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: var(--x-spacer) !important;
  }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: var(--x-spacer) !important;
  }

  .m-lg-2 {
    margin: calc(var(--x-spacer) * 2) !important;
  }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: calc(var(--x-spacer) * 2) !important;
  }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: calc(var(--x-spacer) * 2) !important;
  }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: calc(var(--x-spacer) * 2) !important;
  }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: calc(var(--x-spacer) * 2) !important;
  }

  .m-lg-3 {
    margin: calc(var(--x-spacer) * 4) !important;
  }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: calc(var(--x-spacer) * 4) !important;
  }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: calc(var(--x-spacer) * 4) !important;
  }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: calc(var(--x-spacer) * 4) !important;
  }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: calc(var(--x-spacer) * 4) !important;
  }

  .m-lg-4 {
    margin: calc(var(--x-spacer) * 6) !important;
  }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: calc(var(--x-spacer) * 6) !important;
  }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: calc(var(--x-spacer) * 6) !important;
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: calc(var(--x-spacer) * 6) !important;
  }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: calc(var(--x-spacer) * 6) !important;
  }

  .m-lg-5 {
    margin: calc(var(--x-spacer) * 8) !important;
  }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: calc(var(--x-spacer) * 8) !important;
  }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: calc(var(--x-spacer) * 8) !important;
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: calc(var(--x-spacer) * 8) !important;
  }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: calc(var(--x-spacer) * 8) !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: var(--x-spacer) !important;
  }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: var(--x-spacer) !important;
  }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: var(--x-spacer) !important;
  }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: var(--x-spacer) !important;
  }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: var(--x-spacer) !important;
  }

  .p-lg-2 {
    padding: calc(var(--x-spacer) * 2) !important;
  }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: calc(var(--x-spacer) * 2) !important;
  }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: calc(var(--x-spacer) * 2) !important;
  }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: calc(var(--x-spacer) * 2) !important;
  }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: calc(var(--x-spacer) * 2) !important;
  }

  .p-lg-3 {
    padding: calc(var(--x-spacer) * 4) !important;
  }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: calc(var(--x-spacer) * 4) !important;
  }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: calc(var(--x-spacer) * 4) !important;
  }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: calc(var(--x-spacer) * 4) !important;
  }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: calc(var(--x-spacer) * 4) !important;
  }

  .p-lg-4 {
    padding: calc(var(--x-spacer) * 6) !important;
  }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: calc(var(--x-spacer) * 6) !important;
  }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: calc(var(--x-spacer) * 6) !important;
  }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: calc(var(--x-spacer) * 6) !important;
  }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: calc(var(--x-spacer) * 6) !important;
  }

  .p-lg-5 {
    padding: calc(var(--x-spacer) * 8) !important;
  }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: calc(var(--x-spacer) * 8) !important;
  }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: calc(var(--x-spacer) * 8) !important;
  }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: calc(var(--x-spacer) * 8) !important;
  }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: calc(var(--x-spacer) * 8) !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 87.5rem) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: var(--x-spacer) !important;
  }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: var(--x-spacer) !important;
  }

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: var(--x-spacer) !important;
  }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: var(--x-spacer) !important;
  }

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: var(--x-spacer) !important;
  }

  .m-xl-2 {
    margin: calc(var(--x-spacer) * 2) !important;
  }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: calc(var(--x-spacer) * 2) !important;
  }

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: calc(var(--x-spacer) * 2) !important;
  }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: calc(var(--x-spacer) * 2) !important;
  }

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: calc(var(--x-spacer) * 2) !important;
  }

  .m-xl-3 {
    margin: calc(var(--x-spacer) * 4) !important;
  }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: calc(var(--x-spacer) * 4) !important;
  }

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: calc(var(--x-spacer) * 4) !important;
  }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: calc(var(--x-spacer) * 4) !important;
  }

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: calc(var(--x-spacer) * 4) !important;
  }

  .m-xl-4 {
    margin: calc(var(--x-spacer) * 6) !important;
  }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: calc(var(--x-spacer) * 6) !important;
  }

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: calc(var(--x-spacer) * 6) !important;
  }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: calc(var(--x-spacer) * 6) !important;
  }

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: calc(var(--x-spacer) * 6) !important;
  }

  .m-xl-5 {
    margin: calc(var(--x-spacer) * 8) !important;
  }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: calc(var(--x-spacer) * 8) !important;
  }

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: calc(var(--x-spacer) * 8) !important;
  }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: calc(var(--x-spacer) * 8) !important;
  }

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: calc(var(--x-spacer) * 8) !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: var(--x-spacer) !important;
  }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: var(--x-spacer) !important;
  }

  .pr-xl-1,
  .px-xl-1 {
    padding-right: var(--x-spacer) !important;
  }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: var(--x-spacer) !important;
  }

  .pl-xl-1,
  .px-xl-1 {
    padding-left: var(--x-spacer) !important;
  }

  .p-xl-2 {
    padding: calc(var(--x-spacer) * 2) !important;
  }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: calc(var(--x-spacer) * 2) !important;
  }

  .pr-xl-2,
  .px-xl-2 {
    padding-right: calc(var(--x-spacer) * 2) !important;
  }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: calc(var(--x-spacer) * 2) !important;
  }

  .pl-xl-2,
  .px-xl-2 {
    padding-left: calc(var(--x-spacer) * 2) !important;
  }

  .p-xl-3 {
    padding: calc(var(--x-spacer) * 4) !important;
  }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: calc(var(--x-spacer) * 4) !important;
  }

  .pr-xl-3,
  .px-xl-3 {
    padding-right: calc(var(--x-spacer) * 4) !important;
  }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: calc(var(--x-spacer) * 4) !important;
  }

  .pl-xl-3,
  .px-xl-3 {
    padding-left: calc(var(--x-spacer) * 4) !important;
  }

  .p-xl-4 {
    padding: calc(var(--x-spacer) * 6) !important;
  }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: calc(var(--x-spacer) * 6) !important;
  }

  .pr-xl-4,
  .px-xl-4 {
    padding-right: calc(var(--x-spacer) * 6) !important;
  }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: calc(var(--x-spacer) * 6) !important;
  }

  .pl-xl-4,
  .px-xl-4 {
    padding-left: calc(var(--x-spacer) * 6) !important;
  }

  .p-xl-5 {
    padding: calc(var(--x-spacer) * 8) !important;
  }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: calc(var(--x-spacer) * 8) !important;
  }

  .pr-xl-5,
  .px-xl-5 {
    padding-right: calc(var(--x-spacer) * 8) !important;
  }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: calc(var(--x-spacer) * 8) !important;
  }

  .pl-xl-5,
  .px-xl-5 {
    padding-left: calc(var(--x-spacer) * 8) !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.text-black {
  color: #000 !important;
}

.text-gray-100 {
  color: #F8F9FA !important;
}

.text-gray-200 {
  color: #E9ECEF !important;
}

.text-gray-300 {
  color: #DEE2E6 !important;
}

.text-gray-400 {
  color: #CED4DA !important;
}

.text-gray-500 {
  color: #ADB5BD !important;
}

.text-gray-600 {
  color: #868E96 !important;
}

.text-gray-700 {
  color: #495057 !important;
}

.text-gray-800 {
  color: #343A40 !important;
}

.text-gray-900 {
  color: #212529 !important;
}

.text-danger {
  color: #DC3545 !important;
}

.text-dark {
  color: #212529 !important;
}

.text-info {
  color: #17A2B8 !important;
}

.text-light {
  color: #F8F9FA !important;
}

.text-primary {
  color: #000;
}

.text-secondary {
  color: #006893 !important;
}

.text-tertiary {
  color: #006893 !important;
}

.text-success {
  color: #90AD25 !important;
}

.text-warning {
  color: #FFC107 !important;
}

.text-white {
  color: #fff !important;
}

.text-noselect {
  -webkit-user-select: none;
  user-select: none;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-normal {
  text-transform: none !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-muted {
  color: var(--x-gray-600) !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-break {
  overflow-wrap: break-word !important;
  word-break: break-word !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

@media (min-width: 30rem) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-center {
    text-align: center !important;
  }

  .text-sm-right {
    text-align: right !important;
  }
}

@media (min-width: 48rem) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-center {
    text-align: center !important;
  }

  .text-md-right {
    text-align: right !important;
  }
}

@media (min-width: 62rem) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-center {
    text-align: center !important;
  }

  .text-lg-right {
    text-align: right !important;
  }
}

@media (min-width: 87.5rem) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-center {
    text-align: center !important;
  }

  .text-xl-right {
    text-align: right !important;
  }
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.overflow-auto {
  overflow: auto !important;
  -webkit-overflow-scrolling: touch;
}

.overflow-hidden {
  overflow: hidden !important;
}

.fc .fc-button-primary {
  --x-button-bg-color: #DEE2E6;
  --x-button-border-color: #DEE2E6;
  --x-button-color: #212529;
  --x-button-box-shadow-color: rgba(222, 226, 230, 0.5);
}

.fc .fc-button-primary:focus,
.fc .fc-button-primary.focus,
.fc .fc-button-primary:hover {
  --x-button-bg-color: #cfd5db;
  --x-button-border-color: #c1c9d0;
  --x-button-color: #212529;
}

.fc .fc-button-primary.disabled,
.fc .fc-button-primary:disabled {
  --x-button-bg-color: #DEE2E6;
  --x-button-border-color: #DEE2E6;
  --x-button-color: #868E96;
  --x-button-box-shadow-color: rgba(222, 226, 230, 0.5);
}

.fc .fc-button-primary.disabled:focus,
.fc .fc-button-primary.disabled.focus,
.fc .fc-button-primary:disabled:focus,
.fc .fc-button-primary:disabled.focus {
  --x-button-bg-color: #cfd5db;
  --x-button-border-color: #c1c9d0;
}

.fc .fc-button-primary .spinner-border {
  --x-spinner-color: #212529;
}

.fc .fc-button-primary .fc-icon {
  background: #212529;
}

.fc .fc-button-primary:focus .fc-icon,
.fc .fc-button-primary:hover .fc-icon {
  background: #212529;
}

.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
  --x-button-bg-color: #006893;
  --x-button-border-color: #006893;
  --x-button-color: #fff;
  --x-button-box-shadow-color: rgba(0, 104, 147, 0.5);
}

.fc .fc-button-primary:not(:disabled):active:focus,
.fc .fc-button-primary:not(:disabled):active.focus,
.fc .fc-button-primary:not(:disabled):active:hover,
.fc .fc-button-primary:not(:disabled).fc-button-active:focus,
.fc .fc-button-primary:not(:disabled).fc-button-active.focus,
.fc .fc-button-primary:not(:disabled).fc-button-active:hover {
  --x-button-bg-color: #00567a;
  --x-button-border-color: #004460;
  --x-button-color: #fff;
}

.fc .fc-button-primary:not(:disabled):active.disabled,
.fc .fc-button-primary:not(:disabled):active:disabled,
.fc .fc-button-primary:not(:disabled).fc-button-active.disabled,
.fc .fc-button-primary:not(:disabled).fc-button-active:disabled {
  --x-button-bg-color: #DEE2E6;
  --x-button-border-color: #DEE2E6;
  --x-button-color: #868E96;
  --x-button-box-shadow-color: rgba(222, 226, 230, 0.5);
}

.fc .fc-button-primary:not(:disabled):active.disabled:focus,
.fc .fc-button-primary:not(:disabled):active.disabled.focus,
.fc .fc-button-primary:not(:disabled):active:disabled:focus,
.fc .fc-button-primary:not(:disabled):active:disabled.focus,
.fc .fc-button-primary:not(:disabled).fc-button-active.disabled:focus,
.fc .fc-button-primary:not(:disabled).fc-button-active.disabled.focus,
.fc .fc-button-primary:not(:disabled).fc-button-active:disabled:focus,
.fc .fc-button-primary:not(:disabled).fc-button-active:disabled.focus {
  --x-button-bg-color: #cfd5db;
  --x-button-border-color: #c1c9d0;
}

.fc .fc-button-primary:not(:disabled):active .spinner-border,
.fc .fc-button-primary:not(:disabled).fc-button-active .spinner-border {
  --x-spinner-color: #fff;
}

.fc .fc-button-primary:not(:disabled):active .fc-icon,
.fc .fc-button-primary:not(:disabled).fc-button-active .fc-icon {
  background: #fff;
}

.fc .fc-button-primary:not(:disabled):active:focus .fc-icon,
.fc .fc-button-primary:not(:disabled):active:hover .fc-icon,
.fc .fc-button-primary:not(:disabled).fc-button-active:focus .fc-icon,
.fc .fc-button-primary:not(:disabled).fc-button-active:hover .fc-icon {
  background: #fff;
}

@media (max-width: 47.9375rem) {
  .fc .fc-dayGridMonth-button,
  .fc .fc-dayGridWeek-button {
    display: none !important;
  }
}

.ck.ck-toolbar {
  border: 0 !important;
  border-bottom: 0.0625rem solid var(--ck-color-base-border) !important;
}

.ck.ck-editor {
  border: 0.0625rem solid var(--ck-color-base-border) !important;
  border-radius: var(--ck-border-radius);
  transition: border-color ease-in-out 150ms, box-shadow ease-in-out 150ms !important;
}

.ck.ck-editor.focus {
  border-color: var(--x-input-focus-border) !important;
  box-shadow: 0 0 0 0.1875rem var(--x-input-focus-box-shadow-color);
}

.ck.ck-editor__editable {
  min-height: 15.625rem;
}

.ck.ck-editor__editable_inline {
  border: 0 !important;
}

.ck.ck-balloon-panel {
  z-index: 9999 !important;
}

textarea[data-rich-text-editor="ckeditor"].is-invalid ~ .ck.ck-editor {
  border-color: var(--x-danger) !important;
}

textarea[data-rich-text-editor="ckeditor"].is-invalid ~ .ck.ck-editor.focus {
  border-color: var(--x-danger) !important;
  box-shadow: 0 0 0 0.1875rem rgba(220, 53, 69, 0.25);
}

body.compensate-for-scrollbar {
  overflow: hidden;
}

.fancybox-active {
  height: auto;
}

.fancybox-is-hidden {
  left: -624.9375rem;
  margin: 0;
  position: absolute !important;
  top: -624.9375rem;
  visibility: hidden;
}

.fancybox-container {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  height: 100%;
  left: 0;
  outline: none;
  position: fixed;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  touch-action: manipulation;
  transform: translateZ(0);
  width: 100%;
  z-index: 99992;
}

.fancybox-outer,
.fancybox-inner,
.fancybox-bg,
.fancybox-stage {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.fancybox-outer {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
}

.fancybox-bg {
  background: #0D0D0D;
  opacity: 0;
  transition-duration: inherit;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71);
}

.fancybox-is-open .fancybox-bg {
  opacity: 1;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
}

.fancybox-infobar,
.fancybox-toolbar,
.fancybox-caption,
.fancybox-navigation .fancybox-button {
  direction: ltr;
  opacity: 0;
  position: absolute;
  transition: opacity 0.25s ease, visibility 0s ease 0.25s;
  visibility: hidden;
  z-index: 99997;
}

.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-toolbar .fancybox-toolbar,
.fancybox-show-caption .fancybox-caption,
.fancybox-show-nav .fancybox-navigation .fancybox-button {
  opacity: 1;
  transition: opacity 0.25s ease 0s, visibility 0s ease 0s;
  visibility: visible;
}

.fancybox-infobar {
  color: #CCC;
  font-size: 0.8125rem;
  height: 2.75rem;
  left: 0;
  line-height: 2.75rem;
  min-width: 2.75rem;
  mix-blend-mode: difference;
  padding: 0 0.625rem;
  pointer-events: none;
  top: 0;
  -webkit-user-select: none;
  user-select: none;
}

.fancybox-toolbar {
  right: 0;
  top: 0;
}

.fancybox-stage {
  direction: ltr;
  overflow: visible;
  transform: translateZ(0);
  z-index: 99994;
}

.fancybox-is-open .fancybox-stage {
  overflow: hidden;
}

.fancybox-slide {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: none;
  height: 100%;
  left: 0;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 2.75rem;
  position: absolute;
  text-align: center;
  top: 0;
  transition-property: transform, opacity;
  white-space: normal;
  width: 100%;
  z-index: 99994;
}

.fancybox-slide::before {
  content: "";
  display: inline-block;
  font-size: 0;
  height: 100%;
  vertical-align: middle;
  width: 0;
}

.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--previous,
.fancybox-slide--current,
.fancybox-slide--next {
  display: block;
}

.fancybox-slide--image {
  overflow: hidden;
  padding: 2.75rem 0;
}

.fancybox-slide--image::before {
  display: none;
}

.fancybox-slide--html {
  padding: 0.375rem;
}

.fancybox-content {
  background: #FFF;
  display: inline-block;
  margin: 0;
  max-width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 2.75rem;
  position: relative;
  text-align: left;
  vertical-align: middle;
}

.fancybox-slide--image .fancybox-content {
  animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: transparent;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: 0;
  max-width: none;
  overflow: visible;
  padding: 0;
  position: absolute;
  top: 0;
  transform-origin: top left;
  transition-property: transform, opacity;
  -webkit-user-select: none;
  user-select: none;
  z-index: 99995;
}

.fancybox-can-zoomOut .fancybox-content {
  cursor: zoom-out;
}

.fancybox-can-zoomIn .fancybox-content {
  cursor: zoom-in;
}

.fancybox-can-swipe .fancybox-content,
.fancybox-can-pan .fancybox-content {
  cursor: grab;
}

.fancybox-is-grabbing .fancybox-content {
  cursor: grabbing;
}

.fancybox-container [data-selectable="true"] {
  cursor: text;
}

.fancybox-image,
.fancybox-spaceball {
  background: transparent;
  border: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
}

.fancybox-spaceball {
  z-index: 1;
}

.fancybox-slide--video .fancybox-content,
.fancybox-slide--map .fancybox-content,
.fancybox-slide--pdf .fancybox-content,
.fancybox-slide--iframe .fancybox-content {
  height: 100%;
  overflow: visible;
  padding: 0;
  width: 100%;
}

.fancybox-slide--video .fancybox-content {
  background: #000;
}

.fancybox-slide--map .fancybox-content {
  background: #E5E3DF;
}

.fancybox-slide--iframe .fancybox-content {
  background: #FFF;
}

.fancybox-video,
.fancybox-iframe {
  background: transparent;
  border: 0;
  display: block;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
}

.fancybox-iframe {
  left: 0;
  position: absolute;
  top: 0;
}

.fancybox-error {
  background: #FFF;
  cursor: default;
  max-width: 25rem;
  padding: 2.5rem;
  width: 100%;
}

.fancybox-error p {
  color: #444;
  font-size: 1rem;
  line-height: 1.25rem;
  margin: 0;
  padding: 0;
}

.fancybox-button {
  background: rgba(30, 30, 30, 0.6);
  border: 0;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  height: 2.75rem;
  margin: 0;
  padding: 0.625rem;
  position: relative;
  transition: color 0.2s;
  vertical-align: top;
  visibility: inherit;
  width: 2.75rem;
}

.fancybox-button,
.fancybox-button:visited,
.fancybox-button:link {
  color: #CCC;
}

.fancybox-button:hover {
  color: #FFF;
}

.fancybox-button:focus {
  outline: none;
}

.fancybox-button.fancybox-focus {
  outline: 0.0625rem dotted;
}

.fancybox-button[disabled],
.fancybox-button[disabled]:hover {
  color: #888;
  cursor: default;
  outline: none;
}

.fancybox-button div {
  height: 100%;
}

.fancybox-button svg {
  display: block;
  height: 100%;
  overflow: visible;
  position: relative;
  width: 100%;
}

.fancybox-button svg path {
  fill: currentColor;
  stroke-width: 0;
}

.fancybox-button--play svg:nth-child(2),
.fancybox-button--fsenter svg:nth-child(2) {
  display: none;
}

.fancybox-button--pause svg:nth-child(1),
.fancybox-button--fsexit svg:nth-child(1) {
  display: none;
}

.fancybox-progress {
  background: #FF5268;
  height: 0.125rem;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: scaleX(0);
  transform-origin: 0;
  transition-property: transform;
  transition-timing-function: linear;
  z-index: 99998;
}

.fancybox-close-small {
  background: transparent;
  border: 0;
  border-radius: 0;
  color: #CCC;
  cursor: pointer;
  opacity: 0.8;
  padding: 0.5rem;
  position: absolute;
  right: -0.75rem;
  top: -2.75rem;
  z-index: 401;
}

.fancybox-close-small:hover {
  color: #FFF;
  opacity: 1;
}

.fancybox-slide--html .fancybox-close-small {
  color: currentColor;
  padding: 0.625rem;
  right: 0;
  top: 0;
}

.fancybox-slide--image.fancybox-is-scaling .fancybox-content {
  overflow: hidden;
}

.fancybox-is-scaling .fancybox-close-small,
.fancybox-is-zoomable.fancybox-can-pan .fancybox-close-small {
  display: none;
}

.fancybox-navigation .fancybox-button {
  background-clip: content-box;
  height: 6.25rem;
  opacity: 0;
  position: absolute;
  top: calc(50% - 3.125rem);
  width: 4.375rem;
}

.fancybox-navigation .fancybox-button div {
  padding: 0.4375rem;
}

.fancybox-navigation .fancybox-button--arrow_left {
  left: 0;
  left: env(safe-area-inset-left);
  padding: 1.9375rem 1.625rem 1.9375rem 0.375rem;
}

.fancybox-navigation .fancybox-button--arrow_right {
  padding: 1.9375rem 0.375rem 1.9375rem 1.625rem;
  right: 0;
  right: env(safe-area-inset-right);
}

.fancybox-caption {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.15) 65%, rgba(0, 0, 0, 0.075) 75.5%, rgba(0, 0, 0, 0.037) 82.85%, rgba(0, 0, 0, 0.019) 88%, rgba(0, 0, 0, 0) 100%);
  bottom: 0;
  color: #EEE;
  font-size: 0.875rem;
  font-weight: 400;
  left: 0;
  line-height: 1.5;
  padding: 4.6875rem 2.75rem 1.5625rem;
  pointer-events: none;
  right: 0;
  text-align: center;
  z-index: 99996;
}

.fancybox-caption--separate {
  margin-top: -3.125rem;
}

.fancybox-caption__body {
  max-height: 50vh;
  overflow: auto;
  pointer-events: all;
}

.fancybox-caption a,
.fancybox-caption a:link,
.fancybox-caption a:visited {
  color: #CCC;
  text-decoration: none;
}

.fancybox-caption a:hover {
  color: #FFF;
  text-decoration: underline;
}

.fancybox-loading {
  animation: fancybox-rotate 1s linear infinite;
  background: transparent;
  border: 0.25rem solid #888;
  border-bottom-color: #FFF;
  border-radius: 50%;
  height: 3.125rem;
  left: 50%;
  margin: -1.5625rem 0 0 -1.5625rem;
  opacity: 0.7;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 3.125rem;
  z-index: 99999;
}

@keyframes fancybox-rotate {
  100% {
    transform: rotate(360deg);
  }
}

.fancybox-animated {
  transition-timing-function: cubic-bezier(0, 0, 0.25, 1);
}

.fancybox-fx-slide.fancybox-slide--previous {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

.fancybox-fx-slide.fancybox-slide--next {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.fancybox-fx-slide.fancybox-slide--current {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.fancybox-fx-fade.fancybox-slide--previous,
.fancybox-fx-fade.fancybox-slide--next {
  opacity: 0;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1;
}

.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(1.5, 1.5, 1.5);
}

.fancybox-fx-zoom-in-out.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5);
}

.fancybox-fx-zoom-in-out.fancybox-slide--current {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}

.fancybox-fx-rotate.fancybox-slide--previous {
  opacity: 0;
  transform: rotate(-360deg);
}

.fancybox-fx-rotate.fancybox-slide--next {
  opacity: 0;
  transform: rotate(360deg);
}

.fancybox-fx-rotate.fancybox-slide--current {
  opacity: 1;
  transform: rotate(0deg);
}

.fancybox-fx-circular.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
}

.fancybox-fx-circular.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
}

.fancybox-fx-circular.fancybox-slide--current {
  opacity: 1;
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
}

.fancybox-fx-tube.fancybox-slide--previous {
  transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
}

.fancybox-fx-tube.fancybox-slide--next {
  transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
}

.fancybox-fx-tube.fancybox-slide--current {
  transform: translate3d(0, 0, 0) scale(1);
}

@media (max-width: 29.9375rem) {
  .fancybox-slide {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
  }

  .fancybox-slide--image {
    padding: 0.375rem 0;
  }

  .fancybox-close-small {
    right: -0.375rem;
  }

  .fancybox-slide--image .fancybox-close-small {
    background: #4E4E4E;
    color: #F2F4F6;
    height: 2.25rem;
    opacity: 1;
    padding: 0.375rem;
    right: 0;
    top: 0;
    width: 2.25rem;
  }

  .fancybox-caption {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}

.fancybox-share {
  background: #F4F4F4;
  border-radius: 0.1875rem;
  max-width: 90%;
  padding: 1.875rem;
  text-align: center;
}

.fancybox-share h1 {
  color: #222;
  font-size: 2.1875rem;
  font-weight: 700;
  margin: 0 0 1.25rem;
}

.fancybox-share p {
  margin: 0;
  padding: 0;
}

.fancybox-share__button {
  border: 0;
  border-radius: 0.1875rem;
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 2.5rem;
  margin: 0 0.3125rem 0.625rem;
  min-width: 8.125rem;
  padding: 0 0.9375rem;
  text-decoration: none;
  transition: all 0.2s;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
}

.fancybox-share__button:visited,
.fancybox-share__button:link {
  color: #FFF;
}

.fancybox-share__button:hover {
  text-decoration: none;
}

.fancybox-share__button--fb {
  background: #3B5998;
}

.fancybox-share__button--fb:hover {
  background: #344E86;
}

.fancybox-share__button--pt {
  background: #BD081D;
}

.fancybox-share__button--pt:hover {
  background: #AA0719;
}

.fancybox-share__button--tw {
  background: #1DA1F2;
}

.fancybox-share__button--tw:hover {
  background: #0D95E8;
}

.fancybox-share__button svg {
  height: 1.5625rem;
  margin-right: 0.4375rem;
  position: relative;
  top: -0.0625rem;
  vertical-align: middle;
  width: 1.5625rem;
}

.fancybox-share__button svg path {
  fill: #FFF;
}

.fancybox-share__input {
  background: transparent;
  border: 0;
  border-bottom: 0.0625rem solid #D7D7D7;
  border-radius: 0;
  color: #5D5B5B;
  font-size: 0.875rem;
  margin: 0.625rem 0 0;
  outline: none;
  padding: 0.625rem 0.9375rem;
  width: 100%;
}

.fancybox-thumbs {
  background: #DDD;
  bottom: 0;
  display: none;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 0.125rem 0.125rem 0.25rem;
  position: absolute;
  right: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  top: 0;
  width: 13.25rem;
  z-index: 99995;
}

.fancybox-thumbs-x {
  overflow-x: auto;
  overflow-y: hidden;
}

.fancybox-show-thumbs .fancybox-thumbs {
  display: block;
}

.fancybox-show-thumbs .fancybox-inner {
  right: 13.25rem;
}

.fancybox-thumbs__list {
  font-size: 0;
  height: 100%;
  list-style: none;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  position: relative;
  white-space: nowrap;
  width: 100%;
}

.fancybox-thumbs-x .fancybox-thumbs__list {
  overflow: hidden;
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar {
  width: 0.4375rem;
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-track {
  background: #FFF;
  border-radius: 0.625rem;
  box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-thumb {
  background: #2A2A2A;
  border-radius: 0.625rem;
}

.fancybox-thumbs__list a {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: rgba(0, 0, 0, 0.1);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  float: left;
  height: 4.6875rem;
  margin: 0.125rem;
  max-height: calc(100% - 0.5rem);
  max-width: calc(50% - 0.25rem);
  outline: none;
  overflow: hidden;
  padding: 0;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  width: 6.25rem;
}

.fancybox-thumbs__list a::before {
  border: 0.375rem solid #FF5268;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 99991;
}

.fancybox-thumbs__list a:focus::before {
  opacity: 0.5;
}

.fancybox-thumbs__list a.fancybox-thumbs-active::before {
  opacity: 1;
}

@media (max-width: 29.9375rem) {
  .fancybox-thumbs {
    width: 6.875rem;
  }

  .fancybox-show-thumbs .fancybox-inner {
    right: 6.875rem;
  }

  .fancybox-thumbs__list a {
    max-width: calc(100% - 0.625rem);
  }
}

.file-tree {
  border: var(--x-input-border-width) solid var(--x-gray-500);
  border-radius: var(--x-border-radius);
  height: 13rem;
  margin: var(--x-spacer) 0;
  overflow-y: auto;
  padding: var(--x-spacer);
  position: relative;
}

.file-tree ul {
  margin: 0;
  padding: 0;
}

.file-tree li {
  padding-left: 1.5rem;
  position: relative;
}

.file-tree li::before {
  background: var(--x-primary);
  content: "";
  display: block;
  height: 1.5rem;
  left: 0;
  line-height: 1.75rem;
  -webkit-mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath d='M14,2H6A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2M18,20H6V4H13V9H18V20Z' /%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath d='M14,2H6A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2M18,20H6V4H13V9H18V20Z' /%3e%3c/svg%3e");
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 1.5rem;
  mask-size: 1.5rem;
  padding: 0 0 0 1.625rem;
  position: absolute;
  top: 0.1875rem;
  white-space: nowrap;
  width: 1.5rem;
}

.file-tree li.wait::before {
  -webkit-mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath d='M6,2H18V8H18V8L14,12L18,16V16H18V22H6V16H6V16L10,12L6,8V8H6V2M16,16.5L12,12.5L8,16.5V20H16V16.5M12,11.5L16,7.5V4H8V7.5L12,11.5M10,6H14V6.75L12,8.75L10,6.75V6Z'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath d='M6,2H18V8H18V8L14,12L18,16V16H18V22H6V16H6V16L10,12L6,8V8H6V2M16,16.5L12,12.5L8,16.5V20H16V16.5M12,11.5L16,7.5V4H8V7.5L12,11.5M10,6H14V6.75L12,8.75L10,6.75V6Z'/%3e%3c/svg%3e");
}

.file-tree li.directory::before {
  -webkit-mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath d='M20,18H4V8H20M20,6H12L10,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V8C22,6.89 21.1,6 20,6Z'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath d='M20,18H4V8H20M20,6H12L10,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V8C22,6.89 21.1,6 20,6Z'/%3e%3c/svg%3e");
}

.file-tree li.expanded::before {
  -webkit-mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath d='M6.1,10L4,18V8H21A2,2 0 0,0 19,6H12L10,4H4A2,2 0 0,0 2,6V18A2,2 0 0,0 4,20H19C19.9,20 20.7,19.4 20.9,18.5L23.2,10H6.1M19,18H6L7.6,12H20.6L19,18Z'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath d='M6.1,10L4,18V8H21A2,2 0 0,0 19,6H12L10,4H4A2,2 0 0,0 2,6V18A2,2 0 0,0 4,20H19C19.9,20 20.7,19.4 20.9,18.5L23.2,10H6.1M19,18H6L7.6,12H20.6L19,18Z'/%3e%3c/svg%3e");
}

.file-tree a {
  color: var(--x-gray-900);
  line-height: 1.75rem;
  margin-left: 0.25rem;
  padding: 0.125rem 0.375rem;
  text-decoration: none;
}

.file-tree a:hover {
  color: var(--x-primary);
}

.file-tree a.selected {
  background: var(--x-gray-400);
  color: var(--x-gray-900);
}

.is-invalid ~ .file-tree {
  border-color: var(--x-danger);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@keyframes color-animation {
  0% {
    background-position: 22% 0%;
  }

  100% {
    background-position: 100% 0%;
  }
}

//.color-animation , .modal-header::after , .color-header {
//  background: linear-gradient(90deg, #00B33A, #00ACF1, #00B33A, #FFA000, #00B33A, #00ACF1, #00B33A, #FFA000, #00B33A);
//  background-position: 22% 0;
//  background-size: 300% 100%;
//}
//
//.color-header {
//  height: 0.875rem;
//}
//
html {
  height: 100%;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

main {
  display: block;
  flex: 1 0 auto;
}

.login {
  display: grid;
  grid-template-columns: 1fr;
}

.login-item {
  --x-linear-scale-min-value: var(--x-grid-min-gutter-unitless);
  --x-linear-scale-max-value: var(--x-grid-max-gutter-unitless);
  --x-slope: (var(--x-linear-scale-max-value) - var(--x-linear-scale-min-value)) / (var(--x-linear-scale-max-width) - var(--x-linear-scale-min-width));
  --x-y-axis-intersection: ((var(--x-linear-scale-min-width) * -1 / 16) * var(--x-slope) + (var(--x-linear-scale-min-value) / 16));
  padding-left: clamp(calc(var(--x-linear-scale-min-value) / 16 * 1rem), calc(var(--x-y-axis-intersection) * 1rem + var(--x-slope) * 100vw), calc(var(--x-linear-scale-max-value) / 16 * 1rem));
  padding-right: clamp(calc(var(--x-linear-scale-min-value) / 16 * 1rem), calc(var(--x-y-axis-intersection) * 1rem + var(--x-slope) * 100vw), calc(var(--x-linear-scale-max-value) / 16 * 1rem));
  margin: 0 auto;
  min-width: var(--x-body-min-width);
  width: 100%;
}

.login-item-sm {
  max-width: 25rem;
}

.login-item-md {
  max-width: 34.375rem;
}

.login-item-lg {
  max-width: 56.25rem;
}

.login-header {
  display: grid;
}

@media (min-width: 30rem) {
  .login-header {
    grid-template-columns: repeat(2, auto);
  }
}

@media (max-width: 40rem) {
  .col-700-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-700-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-700-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-700-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-700-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-700-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-700-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-700-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-700-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-700-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-700-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-700-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}



